import cookie from 'react-cookies'

import React, { Fragment, useState } from 'react'

import config from 'src/config'
import { useGetCurrentAgencyContext } from '../hooks/queries'
import { gql } from '@apollo/client'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { useCachePersist } from '../hooks/useCachePersist'

import { useApolloClientContext } from '../hooks/useApolloClientProvider'

import { useSmallChat } from 'src/components/SupportButton'
import { useNavigate } from 'react-router'
import { stytchClient } from './Stytch'

export const USER_ROLES = [
  'USER',
  'ADMIN',
  'DISPATCHER',
  'SUPERVISOR',
  'READ_ONLY'
]

export const roleProtectedRoutes = [
  { route: '/agency/submission', notAllowed: ['READ_ONLY'] },
  { route: '/agency/dispatches', notAllowed: ['READ_ONLY'] },

  {
    route: '/agency/reporting/reports',
    notAllowed: ['USER', 'DISPATCHER']
  },
  {
    route: '/agency/reporting/forms',
    notAllowed: ['USER', 'DISPATCHER']
  },
  {
    route: '/agency/reporting/incidents',
    notAllowed: ['USER', 'DISPATCHER']
  },
  { route: '/agency/stats', notAllowed: ['USER'] },
  {
    route: '/agency/reporting',
    notAllowed: ['USER', 'DISPATCHER']
  },
  { route: '/agency/public-dashboard', allowed: ['ADMIN'] },
  {
    route: '/agency/public/broadcasts',
    allowed: ['ADMIN']
  },
  {
    route: '/agency/public/dashboard',
    allowed: ['ADMIN']
  },
  {
    route: '/agency/profile/roster',
    allowed: ['ADMIN']
  },
  {
    route: '/agency/profile/resources',
    allowed: ['ADMIN']
  },
  {
    route: '/agency/profile/locations',
    allowed: ['ADMIN']
  },
  {
    route: '/agency/profile/assets',
    allowed: ['ADMIN']
  },
  {
    route: '/agency/profile/stat-categories',
    allowed: ['ADMIN']
  },
  {
    route: '/agency/profile/groups',
    allowed: ['ADMIN']
  },
  { route: '/agency/operations/tags', allowed: ['ADMIN'] },
  { route: '/agency/pulse/user', allowed: ['ADMIN', 'SUPERVISOR'] },
  {
    route: '/agency/pulse/resource',
    allowed: ['ADMIN', 'SUPERVISOR']
  },
  {
    route: '/agency/pulse/location',
    allowed: ['ADMIN', 'SUPERVISOR']
  },
  { route: '/agency/pulse/asset', allowed: ['ADMIN', 'SUPERVISOR'] },
  {
    route: '/agency/pulse/reports',
    allowed: ['ADMIN', 'SUPERVISOR']
  }
]

export const getToken = () =>
  stytchClient?.session?.getTokens()?.['session_jwt']

export const decodeJwt = () => {
  return stytchClient.session.getSync()
}

const getMaxAge = exp => {
  return exp
    ? Math.ceil(Math.abs(new Date(exp) - new Date()) / 1000)
    : 60 * 60 * 24 * 7
}

export const useIsLoggedIn = () => {
  const stytch = useStytchB2BClient()

  const session = stytch.session.getSync()

  return {
    session:
      session?.expires_at &&
      new Date(session?.expires_at) > new Date()
        ? session
        : null
  }
}

export const useHandleAuthTokens = () => {
  const sm = useSmallChat()
  const resetChat = sm?.resetChat
  const { resumeCache } = useCachePersist()
  let [loading, setLoading] = useState(false)
  const stytch = useStytchB2BClient()

  async function handleTokens(token, session) {
    setLoading(true)
    if (!token) {
      deleteToken('token')
      deleteToken('session')
    } else {
      const maxAge = getMaxAge()

      setToken(token, 'token', maxAge)

      if (session) {
        setToken(session, 'session', maxAge)
      }
      await stytch.session.authenticate()
    }
    resumeCache()

    resetChat?.()
    setLoading(false)
  }
  return { handleTokens, loading }
}

export const getUserId = () => {
  const session = stytchClient?.session?.getSync()
  return session?.custom_claims?.userId || session?.user_id || null
}

export const setToken = (token, customKey = 'token', maxAge) => {
  const tokenres = cookie.save(customKey, token, {
    path: '/',
    sameSite: 'lax',
    maxAge: maxAge || 60 * 60 * 24 * 7,
    secure: config.stage !== 'development' && true
  })

  return tokenres
}
export const deleteToken = key => {
  if (key) {
    return cookie.remove(key, { path: '/' })
  }
  cookie.remove('cubeJsToken', { path: '/' })
  return cookie.remove('token', { path: '/' })
}

export const LOGOUT = gql`
  mutation Logout($sessionId: String, $userId: String) {
    logout(sessionId: $sessionId, userId: $userId) {
      token
    }
  }
`

export const useLogout = callback => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const cp = useCachePersist()
  const client = useApolloClientContext()
  const stytch = useStytchB2BClient()
  const sm = useSmallChat()
  const resetChat = sm?.resetChat

  const clearCache = cp?.clearCache

  async function logout(navState) {
    setLoading(true)

    try {
      // Clear application state
      resetChat?.()

      // Stop active queries and mutations
      client.stop()

      // Clear Apollo cache and persistent storage
      if (clearCache) {
        await clearCache() // This will handle both persistor and apollo cache clearing
      } else {
        // Fallback if clearCache is not available
        await client.clearStore()
      }

      // Clear other tokens/storage
      deleteToken('cubeJsToken')

      // Execute callback if provided
      if (callback) {
        await callback()
      }

      // Revoke session
      await stytch.session.revoke().catch(error => {
        console.warn('Error revoking Stytch session:', error)
      })

      // Navigate to login
      navigate('/agency/login', navState)
    } catch (error) {
      console.error('Error during logout:', error)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    logout
  }
}

export const RolesOnly = ({ children, ownerId, roles, fallback }) => {
  const { user } = useGetCurrentAgencyContext()
  const { role, id: userId } = user || {}
  if (
    !['SUPER_ADMIN', ...roles].includes(role) &&
    userId !== ownerId
  ) {
    return fallback ? fallback : <Fragment />
  }
  return children
}

export function checkRoleMatch(path, role) {
  if (role === 'SUPER_ADMIN') return true

  const routConfig = roleProtectedRoutes.find(rp => rp.route === path)

  if (routConfig?.notAllowed) {
    return !routConfig?.notAllowed?.includes(role)
  }

  if (routConfig?.allowed) {
    return routConfig?.allowed?.includes(role)
  }

  return true
}
