/** @jsxImportSource theme-ui */

import { lazy, Suspense } from 'react'
import Spinner from 'src/images/icons/Spinner'
import { retry } from 'src/utils'
import { Box, Flex } from 'theme-ui'
import CustomModal from '../../Shared/Elements/Modal'
import ModalHeader from '../../Shared/Elements/ModalHeader'
import { ReadFileButton } from '../../Shared/MarkFilesReadBy'

import Button from '../../Shared/Elements/Button'
import ModalFooter from '../../Shared/Elements/ModalFooter'

const AsyncDocumentViewer = lazy(() =>
  retry(() => import('./DocumentViewer'))
)

const DocumentViewerModal = ({
  f,
  secureFileUrl,
  isOpen,
  setModalOpen
}) => {
  return (
    <CustomModal
      toggle={() => setModalOpen(!isOpen)}
      isOpen={isOpen}
      width="97vw"
    >
      <ModalHeader toggle={() => setModalOpen(!isOpen)}>
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h4
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            {f.name}
          </h4>
          <Flex
            sx={{
              alignItems: 'center'
            }}
          >
            <Button
              variant="naked"
              icon="download"
              target={'_blank'}
              rel="noopener noreferrer"
              download={f.fileName || f.name}
              href={secureFileUrl}
            />
            <ReadFileButton file={f} />
          </Flex>
        </Flex>
      </ModalHeader>
      <Box sx={{ position: 'relative' }}>
        <Suspense fallback={Spinner}>
          <AsyncDocumentViewer
            uri={secureFileUrl}
            fileType={f.fileType}
          />
        </Suspense>
      </Box>
      <ModalFooter>
        <ReadFileButton file={f} />
      </ModalFooter>
    </CustomModal>
  )
}

export default DocumentViewerModal
