import get from 'lodash/get'

import useGetTodaysAssignment from './useGetTodaysAssignment'
import useGetTodaysDispatcher from './useGetTodaysDispatcher'

export default ({ noteWasDispatch } = {}) => {
  const { id } = useGetTodaysDispatcher()
  const { data } = useGetTodaysAssignment()

  const responders = get(data, 'todaysResponders', [])
  const primaryResponder = responders.find(i => i.primary)

  const noteWriter = primaryResponder || responders[0]
  let writtenBy = {}
  if (id) {
    writtenBy = {
      noteWrittenBy: id,
      noteWasDispatch: true
    }
  } else if (
    noteWriter &&
    (noteWriter.responderId || noteWriter.positionId)
  ) {
    writtenBy = {
      ...(noteWriter.responderId && {
        noteWrittenBy: noteWriter.responderId
      }),
      ...(noteWriter.positionId && {
        noteWrittenByPosition: noteWriter.positionId
      })
    }
  }

  return { noteWasDispatch, ...writtenBy }
}
