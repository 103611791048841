import { gql, useQuery, useMutation } from '@apollo/client'
import { useApolloClientContext } from './useApolloClientProvider'
import { UPDATE_DAILY_DISPATCHER_STRATEGY } from './mutations'
import { cleanGraphQLError } from '../components/Forms/FormError'
import { useGetCurrentAgencyContext } from './queries'
import { useToast } from 'src/components/toasts'
import { useEffect, useRef } from 'react'

export const GET_TODAYS_DISPATCHER = gql`
  query getTodaysDispatcher {
    todaysDispatcher @client
  }
`

const useGetTodaysDispatcher = () => {
  const client = useApolloClientContext()
  const { add } = useToast()
  const { data, error, refetch, loading } = useQuery(
    GET_TODAYS_DISPATCHER
  )

  const [
    updateDailyDispatcherStrategy,
    { loading: updatingDailyDispatcherStrategy }
  ] = useMutation(UPDATE_DAILY_DISPATCHER_STRATEGY)

  async function updateDailyDispatcher(updateData) {
    try {
      const normalizedData = {
        todaysDispatcher: updateData.todaysDispatcher || null
      }

      await client.writeQuery({
        query: GET_TODAYS_DISPATCHER,
        data: normalizedData
      })

      if (normalizedData.todaysDispatcher) {
        add({
          content: 'Your daily dispatcher has been configured.',
          color: 'success'
        })
      }

      if (updateData?.hasOwnProperty('dailyDispatcherStrategy')) {
        await updateDailyDispatcherStrategy({
          variables: {
            dailyDispatcherStrategy: normalizedData.todaysDispatcher
              ? updateData.dailyDispatcherStrategy || null
              : null,
            autoFillDispatcher: normalizedData.todaysDispatcher
          }
        }).catch(e => {
          console.error(
            'Error updating daily dispatcher strategy:',
            e
          )
        })
      }

      return await client.readQuery({
        query: GET_TODAYS_DISPATCHER
      })
    } catch (e) {
      console.error('Error updating daily responder:', e)
      throw e
    }
  }

  async function setupDailyDispatchers(
    dailyDispatcherStrategy,
    options = {}
  ) {
    if (dailyDispatcherStrategy === 'AUTO' && options.dispatcherId) {
      await updateDailyDispatcher({
        todaysDispatcher: options.dispatcherId
      })
    }
  }

  return {
    setupDailyDispatchers,
    error: error && cleanGraphQLError(error.message),
    loading: loading || updatingDailyDispatcherStrategy,
    updateDailyDispatcher,
    refetch,
    data,
    id: data?.todaysDispatcher
  }
}

const RESET_STATUS_KEY = 'dailyDispatcherResetStatus'
const RESET_LOCK_KEY = 'dailyDispatcherResetLock'

export const useDispatcherResetScheduler = () => {
  const client = useApolloClientContext()
  const { add } = useToast()
  const { user } = useGetCurrentAgencyContext()
  const resetTimeoutRef = useRef(null)
  const isResettingRef = useRef(false)

  const clearTimeouts = () => {
    if (resetTimeoutRef.current) {
      clearTimeout(resetTimeoutRef.current)
      resetTimeoutRef.current = null
    }
  }

  const acquireResetLock = () => {
    const now = new Date().getTime()
    const existingLock = localStorage.getItem(RESET_LOCK_KEY)

    if (existingLock) {
      const lockTime = parseInt(existingLock)
      if (now - lockTime < 60000) return false
    }

    localStorage.setItem(RESET_LOCK_KEY, now.toString())
    return true
  }

  const releaseResetLock = () => {
    localStorage.removeItem(RESET_LOCK_KEY)
  }

  const scheduleNextReset = () => {
    const now = new Date()
    const nextReset = new Date(now)

    if (now.getHours() >= 0 && now.getMinutes() >= 0) {
      nextReset.setDate(nextReset.getDate() + 1)
    }

    nextReset.setHours(0, 0, 0, 0)

    const msUntilReset = nextReset.getTime() - now.getTime()

    console.log('Scheduling next reset:')
    console.log('Current time:', now.toLocaleString())
    console.log('Next reset time:', nextReset.toLocaleString())
    console.log('Hours until reset:', msUntilReset / (1000 * 60 * 60))

    return {
      nextResetTime: nextReset.getTime(),
      msUntilReset
    }
  }

  const persistResetStatus = nextResetTime => {
    const status = {
      isResetEnabled: true,
      nextResetTime,
      lastUpdated: new Date().getTime()
    }
    console.log('Persisting reset status:', status)
    localStorage.setItem(RESET_STATUS_KEY, JSON.stringify(status))
  }

  const clearResetStatus = () => {
    console.log('Clearing reset status')
    localStorage.removeItem(RESET_STATUS_KEY)
    releaseResetLock()
    clearTimeouts()
  }

  const resetDailyDispatcher = async () => {
    try {
      await client.writeQuery({
        query: GET_TODAYS_DISPATCHER,
        data: {
          todaysDispatcher: null
        }
      })

      add({
        content: 'Your dispatcher has been reset.',
        color: 'success'
      })
    } catch (e) {
      console.error('Error resetting daily Dispatcher:', e)
    }
  }

  const setupResetSchedule = async () => {
    if (isResettingRef.current) {
      console.log('Reset already in progress, skipping...')
      return
    }

    console.log('Setting up reset schedule...')
    clearTimeouts()

    const { nextResetTime, msUntilReset } = scheduleNextReset()
    persistResetStatus(nextResetTime)

    resetTimeoutRef.current = setTimeout(async () => {
      if (!acquireResetLock()) {
        console.log('Another instance is handling the reset')
        return
      }

      try {
        isResettingRef.current = true
        await resetDailyDispatcher()
        await setupResetSchedule()
      } finally {
        isResettingRef.current = false
        releaseResetLock()
      }
    }, msUntilReset)
  }

  useEffect(() => {
    if (!user) return

    const initializeResetSchedule = async () => {
      const resetStatus = localStorage.getItem(RESET_STATUS_KEY)

      if (user?.dailyDispatcherStrategy === 'RESET') {
        console.log('Reset strategy enabled, scheduling reset...')

        if (resetStatus) {
          const status = JSON.parse(resetStatus)
          const now = new Date().getTime()

          if (now > status.nextResetTime) {
            if (acquireResetLock()) {
              try {
                await resetDailyDispatcher()
                await setupResetSchedule()
              } finally {
                releaseResetLock()
              }
            }
          } else {
            const msUntilReset = status.nextResetTime - now
            resetTimeoutRef.current = setTimeout(async () => {
              if (acquireResetLock()) {
                try {
                  await resetDailyDispatcher()
                  await setupResetSchedule()
                } finally {
                  releaseResetLock()
                }
              }
            }, msUntilReset)
          }
        } else {
          await setupResetSchedule()
        }
      } else {
        clearResetStatus()
      }
    }

    initializeResetSchedule()

    return () => {
      clearTimeouts()
      releaseResetLock()
    }
  }, [user?.dailyDispatcherStrategy])

  return {
    clearResetStatus
  }
}

export default useGetTodaysDispatcher
