import { useMutation } from '@apollo/client'

import {
  ARCHIVE_MANY_SUBMISSION,
  ARCHIVE_SUBMISSION,
  UPDATE_SUBMISSION
} from 'src/app/hooks/mutations'
import ModalButton from '../Shared/ModalButton'
import { useToast } from 'src/components/toasts'

import { RequestPDFReport } from '../Reporting/StatReport'

import Button from 'src/app/components/Shared/Elements/Button'
import { Flex } from 'theme-ui'

import { GET_SUBMISSION } from 'src/app/hooks/queries'
import cloneDeep from 'lodash/cloneDeep'
import { RolesOnly } from 'src/app/services/auth'
import { cleanGraphQLError } from '../Forms/FormError'
import CustomRadioButtonsInput from '../Forms/CustomRadioButtonsInput'
import { useContext, useState } from 'react'
import { SubmissionModalContext } from '../Shared/SubmissionModalLayout'
import set from 'lodash/set'
import get from 'lodash/get'

const SubmissionsTableActions = ({
  submissionId,
  isArchived = false,
  hasRelated,
  variables,
  cacheKey,
  cacheQuery,
  onFinish
}) => {
  const modalContext = useContext(SubmissionModalContext)
  const showSubmissionModal = modalContext?.showSubmissionModal
  return (
    <Flex sx={{ gap: '12px' }}>
      <RolesOnly
        roles={['ADMIN', 'DISPATCHER', 'SUPERVISOR', 'READ_ONLY']}
      >
        <ModalButton
          buttonLabel="Report"
          buttonProps={{ variant: 'warning', icon: 'download' }}
          isValidToggle={() => {
            return true
          }}
        >
          {({ toggle }) => {
            return (
              <RequestPDFReport
                incidentId={submissionId}
                onClose={toggle}
              />
            )
          }}
        </ModalButton>
      </RolesOnly>

      <RolesOnly roles={['ADMIN', 'DISPATCHER', 'SUPERVISOR']}>
        <Button
          variant="linkGray"
          onClick={() => {
            showSubmissionModal({
              id: submissionId,
              extendedQuickSurvey: true,
              useTodaysPosition: false,
              stickySubmit: true,

              modalContentProps: {
                overflow: 'auto'
              },
              modalProps: {
                height: '90vh',
                shouldCloseOnEsc: false
              },

              mutationOptions: {
                refetchQueries: [
                  'GetEvents',
                  {
                    query: GET_SUBMISSION,
                    variables: {
                      where: {
                        id: submissionId
                      }
                    }
                  }
                ]
              }
            })
          }}
        >
          Edit
        </Button>
      </RolesOnly>
      <RolesOnly roles={['ADMIN', 'DISPATCHER', 'SUPERVISOR']}>
        <ArchiveSubmissionModal
          submissionId={submissionId}
          isArchived={isArchived}
          hasRelated={hasRelated}
          onFinish={onFinish}
          variables={variables}
          cacheQuery={cacheQuery}
          cacheKey={cacheKey}
        />
      </RolesOnly>
    </Flex>
  )
}

export const ArchiveSubmissionModal = ({
  submissionId,
  isArchived,
  variables,
  cacheQuery,
  cacheKey,
  hasRelated
}) => {
  const { add } = useToast()
  const [archiveOneSubmission, { loading }] = useMutation(
    ARCHIVE_SUBMISSION,
    {
      // refetchQueries: ['GetEvents'],
      update: (store, { data: { archiveOneSubmission } }) => {
        try {
          if (!cacheQuery) return
          // Read the data from our cache for this query.
          let data = cloneDeep(
            store.readQuery({
              query: cacheQuery,
              variables
            })
          )

          let cacheData = get(data, cacheKey)

          if (!cacheData) return

          if (cacheData?.feed) {
            // Update the cache data by filtering out the archived response
            cacheData = {
              ...cacheData,
              feed:
                cacheData.feed?.filter(
                  d => d.id !== archiveOneSubmission.id
                ) || []
            }
          } else {
            cacheData =
              cacheData?.filter(
                d => d.id !== archiveOneSubmission.id
              ) || []
          }

          set(data, cacheKey, cacheData)

          // Write our data back to the cache.
          store.writeQuery({
            query: cacheQuery,
            data: data,
            variables: variables
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  )
  return (
    <ModalButton
      buttonProps={{
        variant: isArchived ? 'linkSuccess' : 'linkDanger',
        icon: isArchived ? 'restore' : 'trash'
      }}
      modalHeader={
        hasRelated
          ? `Would you like to ${isArchived ? 'restore' : 'archive'}
          the entire call or just this incident?`
          : `Are you sure you want to
          ${isArchived ? 'restore' : 'archive'} this incident?`
      }
      renderModalActions={toggle => [
        hasRelated && (
          <Button
            onClick={async () => {
              await archiveOneSubmission({
                variables: {
                  id: submissionId,
                  withRelated: true,
                  restore: isArchived
                }
              })
              add({
                content: `Call for Service ${
                  isArchived ? 'Restored' : 'Archived'
                }!`,
                color: 'success'
              })
              toggle()
            }}
            variant="secondary"
            icon={loading && 'spinner'}
            disabled={loading}
          >
            {isArchived
              ? 'Restore Entire Call'
              : 'Archive Entire Call'}
          </Button>
        ),
        <Button
          onClick={async () => {
            await archiveOneSubmission({
              variables: {
                id: submissionId,
                restore: isArchived
              }
              // refetchQueries: ['GetEvents']
            })
            add({
              content: `Submission ${
                isArchived ? 'Restored' : 'Archived'
              }!`,
              color: 'success'
            })
            toggle()
          }}
          variant="danger"
          icon={loading && 'spinner'}
          disabled={loading}
        >
          {isArchived
            ? 'Restore this incident'
            : 'Archive this incident'}
        </Button>
      ]}
    />
  )
}

export const EditVictimsCount = ({
  editText,
  submissionId,
  count,
  cacheQuery,
  id,
  cacheKey,
  onFinish,
  variables
}) => {
  const { add } = useToast()
  const [newCount, setNewCount] = useState(count)

  const [updateOneSubmission, { loading }] = useMutation(
    UPDATE_SUBMISSION,
    {
      update: store => {
        if (!cacheQuery) return
        // Read the data from our cache for this query.
        const data = cloneDeep(
          store.readQuery({
            query: cacheQuery,
            variables
          })
        )

        let cacheData = get(data, cacheKey)
        if (!cacheData) return

        if (cacheData?.feed) {
          // Update the cache data by filtering out the archived response
          cacheData = {
            ...cacheData,
            feed:
              cacheData?.feed?.map(d => {
                if (d.id === id) {
                  return {
                    ...d,
                    breakdown: d.breakdown.map(b => {
                      const countUpdated = b.ids?.includes(
                        submissionId
                      )
                        ? newCount
                        : b.count - count + newCount

                      if (b.ids?.includes(submissionId)) {
                        return { ...b, involvedParties: countUpdated }
                      }
                      return b
                    })
                  }
                }
                return d
              }) || []
          }
        } else {
          cacheData =
            cacheData?.map(d => {
              if (d.id === id) {
                return {
                  ...d,
                  breakdown: d.breakdown.map(b => {
                    const countUpdated = b.ids?.includes(submissionId)
                      ? newCount
                      : b.count - count + newCount
                    if (b.ids?.includes(submissionId)) {
                      return { ...b, involvedParties: countUpdated }
                    }
                    return b
                  })
                }
              }
              return d
            }) || []
        }

        // Using lodash's set to update the data object with the new array
        set(data, cacheKey, cacheData)

        // Write our data back to the cache.
        store.writeQuery({
          query: cacheQuery,
          data: data,
          variables: variables
        })
      }
    }
  )

  const ready = newCount !== count && newCount > 0

  return (
    <ModalButton
      onClose={() => setNewCount(count)}
      buttonLabel={editText}
      buttonProps={{
        variant: 'link',
        icon: !editText && 'pencil',
        sx: {
          p: '3px'
        }
      }}
      contentSize="sm"
      modalHeader={'Edit Victims Count'}
      renderModalActions={toggle => [
        <Button
          onClick={async () => {
            const res = await updateOneSubmission({
              variables: {
                where: { id: submissionId },
                data: { count: newCount }
              }
            })

            if (!res.errors) {
              add({
                content: `Updated!`,
                color: 'success'
              })

              if (onFinish) onFinish()

              toggle()
            } else {
              add({
                content: cleanGraphQLError(res.errors[0]),
                color: 'danger'
              })
            }
          }}
          variant="primary"
          icon={loading && 'spinner'}
          disabled={loading || !ready}
        >
          Save
        </Button>
      ]}
    >
      {() => {
        return (
          <CustomRadioButtonsInput
            value={newCount ?? count}
            name="victimsCount"
            handleSelect={e => {
              setNewCount(e.value)
            }}
            options="numberPad"
            noZero={true}
            minValue={0}
            withInput={true}
          />
        )
      }}
    </ModalButton>
  )
}

export const ArchiveManySubmissionsModal = ({
  ids,
  isArchived,
  variables,
  cacheQuery,
  onFinish,
  cacheKey,
  refetchOnFinish,
  confirmText
}) => {
  const { add } = useToast()
  const [archiveManySubmissions, { loading }] = useMutation(
    ARCHIVE_MANY_SUBMISSION,
    {
      refetchQueries: refetchOnFinish
        ? [
            {
              query: cacheQuery,
              variables
            }
          ]
        : [],
      update: store => {
        try {
          if (!cacheQuery) return
          // Read the data from our cache for this query.
          const data = cloneDeep(
            store.readQuery({
              query: cacheQuery,
              variables
            })
          )

          // Using lodash's get to handle dot notation in cacheKey
          let cacheData = get(data, cacheKey)

          if (!cacheData) return

          if (cacheData?.feed) {
            // Update the cache data by filtering out the archived response
            cacheData = {
              ...cacheData,
              feed: cacheData.feed?.filter(
                d =>
                  !d?.submissionIds ||
                  d?.submissionIds?.some(id => !ids.includes(id))
              )
            }
          } else {
            cacheData =
              cacheData?.filter(
                d =>
                  !d?.submissionIds ||
                  d?.submissionIds?.some(id => !ids.includes(id))
              ) || []
          }

          // Using lodash's set to update the data object with the new array
          set(data, cacheKey, cacheData)

          // Write our data back to the cache.
          store.writeQuery({
            query: cacheQuery,
            data: data,
            variables: variables
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  )
  return (
    <ModalButton
      buttonProps={{
        variant: 'link',
        icon: isArchived ? 'restore' : 'trash',
        iconColor: isArchived ? 'success' : 'danger',
        sx: {
          color: isArchived ? 'success' : 'danger'
        }
      }}
      modalHeader={
        confirmText ||
        `Are you sure you would like to ${
          isArchived ? 'restore' : 'archive'
        } these incidents?`
      }
      renderModalActions={toggle => (
        <Button
          onClick={async () => {
            const res = await archiveManySubmissions({
              variables: {
                ids,
                restore: isArchived
              }
            })

            if (res?.data?.archiveManySubmissions?.success) {
              add({
                content: `Call for Service ${
                  isArchived ? 'Restored' : 'Archived'
                }!`,
                color: 'success'
              })
              if (onFinish) onFinish()
              toggle()
            } else {
              add({
                content: cleanGraphQLError(res.errors[0]),
                color: 'danger'
              })
            }
          }}
          variant="danger"
          icon={loading && 'spinner'}
          disabled={loading}
        >
          {isArchived ? 'Restore Calls' : 'Archive Calls'}
        </Button>
      )}
    />
  )
}

export default SubmissionsTableActions
