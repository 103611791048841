/** @jsxImportSource theme-ui */

import chroma from 'chroma-js'

import { cloneElement, useMemo } from 'react'
import Select, { components } from 'react-select'

import ModalButton from '../Shared/ModalButton'
import CreatableSelect from 'react-select/creatable'
import Button from '../Shared/Elements/Button'
import ListGroup from '../Shared/Elements/ListGroup'
import ListGroupItem from '../Shared/Elements/ListGroupItem'
import { Checkbox, Flex } from 'theme-ui'
import { useThemeUI } from 'theme-ui'
import { getContrastColor } from 'src/utils/getContrastColor'

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <Flex sx={{ alignItems: 'center' }}>
          <Checkbox
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          {props.label}
        </Flex>
      </components.Option>
    </div>
  )
}

const ValueContainer = ({
  children,
  getValue,
  selectProps,
  ...props
}) => {
  var length = getValue().length
  const searchInput = selectProps?.inputValue || ''

  return (
    <components.ValueContainer {...props}>
      {searchInput.length < 1 && (
        <components.Placeholder
          {...props}
          isFocused={props.isFocused}
        >
          {length < 1 && selectProps.placeholder}
        </components.Placeholder>
      )}
      {cloneElement(children[1])}
      {length > 0 && `(${length})`}
    </components.ValueContainer>
  )
}

const getColoredOptionStyles = theme => {
  return {
    multiValue: (styles, { data, ...rest }) => {
      const dataColor =
        data && (theme.rawColors[data.color] || data.color)
      const color = !dataColor ? chroma('#fff') : chroma(dataColor)
      return {
        ...styles,
        backgroundColor: color.alpha(1).css()
      }
    },
    multiValueLabel: (styles, { data }) => {
      const dataColor =
        data && (theme.rawColors[data.color] || data.color)
      return {
        ...styles,
        fontWeight: 'bold',
        color: getContrastColor(dataColor, 4)
      }
    },
    multiValueRemove: (styles, { data }) => {
      const dataColor =
        data && (theme.rawColors[data.color] || data.color)
      return {
        ...styles,
        color: getContrastColor(dataColor),
        ':hover': {
          backgroundColor: dataColor,
          color: 'white'
        }
      }
    },
    option: (styles, { data, isDisabled, isSelected }) => {
      const dataColor =
        data && (theme.rawColors[data.color] || data.color)

      if (!dataColor) return styles

      const color = chroma(dataColor).css() // Ensure we get a CSS-compatible color string

      return {
        ...styles, // Spread any existing styles to keep them
        background: isSelected ? theme.colors.primaryLight : '',
        color: isDisabled ? theme.colors.gray300 : theme.colors.text,
        position: 'relative', // Needed to position the ::before pseudo-element

        '::after': {
          content: '""', // Required for ::before to work
          display: 'block',
          position: 'absolute',
          left: 3,
          top: '50%',
          width: '8px', // Size of the dot/box
          height: '8px', // Size of the dot/box
          backgroundColor: color,
          borderRadius: '50%', // Makes it a dot. For a box, remove this line.
          transform: 'translateY(-50%)' // Center it vertically
        }
      }
    }
  }
}

const CustomSelectInput = ({
  coloredHover,
  loading,
  colors,
  customStyles,
  withColor,
  useSummary,
  clearAll,
  clearItem,
  creatable,
  quickSelectFunction,
  quickSelectTitle,
  validateOnCreate,
  styles,
  ...props
}) => {
  const { theme } = useThemeUI()

  const selectProps = {
    hideSelectedOptions: false,
    components: {
      ...(props.isMulti && { Option }),
      ...(useSummary && { ValueContainer })
    },
    styles: {
      control: (styles, { isDisabled }) => {
        return {
          ...styles,
          boxShadow: 'none',
          color: isDisabled ? theme.colors.gray400 : theme.colors.text
        }
      },
      groupHeading: styles => {
        return {
          ...styles,
          fontSize: 14
        }
      },
      option: (styles, { isDisabled, isSelected }) => {
        return {
          ...styles,
          background: isSelected ? theme.colors.gray300 : '',
          color: isDisabled ? theme.colors.gray400 : theme.colors.text
        }
      },
      singleValue: () => ({
        // none of react-select's styles are passed to <Control />
        fontSize: 16
      }),
      ...customStyles,
      ...(withColor && getColoredOptionStyles(theme)),
      ...styles
    },
    theme: t => {
      return {
        ...t,
        borderRadius: '0.2rem',
        flex: 1,
        colors: {
          ...t.colors,
          neutral0: theme.colors.background,
          neutral5: theme.colors.gray100,
          neutral10: theme.colors.gray200,
          neutral20: theme.colors.gray300,
          neutral30: theme.colors.gray300,
          neutral40: theme.colors.gray400,
          neutral50: theme.colors.gray500,
          neutral60: theme.colors.gray600,
          neutral70: theme.colors.gray700,
          neutral80: theme.colors.gray800,
          neutral90: theme.colors.gray900,

          primary25: theme.colors.primaryLight,
          primary: theme.colors.gray700,
          ...colors
        }
      }
    },
    isLoading: loading,
    ...props
  }

  const quickSelectOpt = useMemo(
    () => quickSelectFunction && quickSelectFunction(props?.options),
    [props?.options, quickSelectFunction]
  )

  return (
    <>
      {creatable ? (
        <CreatableSelect
          getNewOptionData={(inputValue, optionLabel) => {
            return {
              id: optionLabel,
              name: inputValue,
              __isNew__: true
            }
          }}
          isValidNewOption={validateOnCreate}
          {...selectProps}
          blurInputOnSelect={selectProps.isMulti ? false : true}
          closeMenuOnSelect={selectProps.isMulti ? false : true}
        />
      ) : (
        <Select
          {...selectProps}
          blurInputOnSelect={selectProps.isMulti ? false : true}
          closeMenuOnSelect={selectProps.isMulti ? false : true}
          isMulti={selectProps.isMulti}
          menuPlacement="auto"
        />
      )}
      {useSummary && props.value?.length > 0 && (
        <ModalButton
          buttonProps={{ variant: 'link' }}
          buttonLabel={`View Selected (${props.value?.length})`}
        >
          {() => {
            return (
              <>
                {loading && <div>Loading...</div>}
                {clearAll && (
                  <Button
                    sx={{ marginBottom: '10px' }}
                    variant="danger"
                    onClick={clearAll}
                  >
                    Clear All
                  </Button>
                )}
                <ListGroup sx={{ mt: '10px' }}>
                  {props.value &&
                    props.value
                      .filter(v => !v.isGroup)
                      .map(val => (
                        <ListGroupItem
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '3px 10px'
                          }}
                        >
                          {val.label || val.name}{' '}
                          <Button
                            variant="linkDanger"
                            onClick={() => {
                              const selected = props.value.filter(
                                v => v.id !== val.id
                              )
                              return clearItem
                                ? clearItem(val, selected)
                                : props.onChange(selected)
                            }}
                            icon="close"
                          />
                        </ListGroupItem>
                      ))}
                </ListGroup>
              </>
            )
          }}
        </ModalButton>
      )}
      {quickSelectFunction && quickSelectTitle && (
        <Flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button
            size="sm"
            sx={{ marginTop: '5px', padding: '2px', py: '0px' }}
            variant="warning"
            onClick={() => {
              const user = quickSelectOpt

              if (user) props?.onChange(user)
            }}
          >
            {quickSelectTitle}
          </Button>
        </Flex>
      )}
    </>
  )
}

export default CustomSelectInput
