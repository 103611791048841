import React, { useCallback, useLayoutEffect, useState } from 'react'

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache
} from '@apollo/client'
import apolloOptions, { persistorOptions } from './Apollo'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import config from 'src/config'

import ErrorBoundaryPage from '../components/Shared/ErrorBoundaryPage'
import { CachePersistor } from 'apollo3-cache-persist'
import possibleTypes from '../../possibleTypes.json'
import Spinner from 'src/images/icons/Spinner'
import get from 'lodash/get'

import { StytchB2BProvider } from '@stytch/react/b2b'
import { getUserId } from './auth'
import { VersionNotificationAlert } from '../components/Nav/TopNav/NotificationAlert'
import { theme } from 'src/styles'
import { ThemeProvider } from 'theme-ui'
import { stytchClient } from './Stytch'
import { CachePersistActionContext } from '../hooks/useCachePersist'
import PusherProvider from './pusher'
import localforage from 'localforage'

export const isProduction = config.stage === 'production'

const bugsnagClient = Bugsnag.start({
  apiKey: config.bugSnagKey,
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: config.stage,
  autoDetectErrors: true,
  appVersion: `${config.frontendVersion}`,
  redactedKeys: ['password', 'token', 'session_jwt', 'session'],
  onError: function(event) {
    // Add additional diagnostic information

    const error =
      event?.originalError?.message ||
      event?.errors[0]?.errorMessage ||
      ''

    if (error?.includes('disconnected')) {
      return false
    }

    if (error?.includes("Unexpected token '<'")) {
      return false
    }

    if (
      error?.startsWith('QuotaExceededError') ||
      error?.startsWith('InvalidStateError') ||
      error?.includes('Error persisting cache')
    ) {
      console.log('Cache Purged..')
      return false
    }
    const userId = getUserId()
    console.log('Adding medadata to error', userId)
    event.setUser(userId)
    const sessionId = get(
      window,
      'posthog.sessionRecording.sessionId',
      false
    )

    const sessionUrl =
      sessionId &&
      `https://app.posthog.com/recordings?#sessionRecordingId=${sessionId}`

    event.addMetadata('posthog', {
      url: sessionUrl
    })

    event.addMetadata('errorInfo', {
      error: JSON.stringify(event.originalError || event?.errors[0])
    })
  },
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = bugsnagClient
  .getPlugin('react')
  .createErrorBoundary(React)

export const WrapRootReact = ({ children }) => {
  const [client, setClient] = useState()
  const [persistor, setPersistor] = useState()

  useLayoutEffect(() => {
    function clearLoader() {
      const ele = document.getElementById('ipl-progress-indicator')
      if (ele) {
        // fade out
        ele.classList.add('available')

        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 100)
      }
    }

    async function init() {
      const cache = new InMemoryCache({
        possibleTypes
      })
      try {
        let newPersistor = new CachePersistor({
          cache,
          ...persistorOptions
        })
        await newPersistor.restore()
        setPersistor(newPersistor)
      } finally {
        const c = new ApolloClient({
          ...apolloOptions,
          cache
        })

        setClient(c)
        clearLoader()
      }
    }

    init()
  }, [])

  async function clearCache() {
    if (!persistor || !client) {
      return
    }

    try {
      await persistor.pause() // Pause automatic persistence
      await client.clearStore() // Clear the apollo cache
      await persistor.purge() // Purge the persistent storage
      await client.cache.reset() // Reset the in-memory cache
      localStorage.clear() // Clear all localStorage data
      await persistor.persist() // Restart automatic persistence
    } catch (e) {
      console.log('Error clearing cache', e)
      // Attempt cleanup even if error occurs
      client?.cache.reset()
      localStorage.clear()
    }
  }

  const resumeCache = useCallback(
    async cb => {
      if (!persistor) {
        return
      }
      // Let's assume the user logs in.
      // First: do whatever is necessary to set the user's session.
      // Next: you absolutely must reset the store. This will clear the prior user's data from
      // memory and will cause all of the open queries to refetch using the new user's session.
      try {
        await client.resetStore()

        // Resume cache persistence. The cache storage provider will be empty right now,
        // but it will written with the new user's data the next time the trigger fires.
        await persistor.resume()

        return cb && cb()
      } catch (e) {
        console.log('Error clearing cache', e)
      }
    },
    [persistor, client]
  )
  if (!client) {
    return <Spinner centered />
  }
  return (
    <ErrorBoundary
      FallbackComponent={({ clearError, ...rest }) => {
        return <ErrorBoundaryPage clearError={clearError} />
      }}
    >
      <ThemeProvider theme={theme}>
        <StytchB2BProvider stytch={stytchClient}>
          <ApolloProvider client={client}>
            <PusherProvider>
              <CachePersistActionContext.Provider
                value={{ persistor, clearCache, resumeCache }}
              >
                <VersionNotificationAlert />
                {children}
              </CachePersistActionContext.Provider>
            </PusherProvider>
          </ApolloProvider>
        </StytchB2BProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}
