import { useMutation } from '@apollo/client'

import { cleanGraphQLError } from './FormError'
import { useToast } from 'src/components/toasts'
import useForm from 'src/app/hooks/useForms'
import ModalButton from '../Shared/ModalButton'
import DatePickerInput from './DatePickerInput'
import { CREATE_BULLETIN } from '../../hooks/mutations'
import RichTextInput, {
  CustomRichText
} from '../Shared/RichTextInput'
import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import Input from '../Shared/Elements/Input'
import FormGroup from '../Shared/Elements/FormGroup'
import Form from '../Shared/Elements/Form'
import ModalFooter from '../Shared/Elements/ModalFooter'
import Switch from '../Shared/Elements/Switch'

import { PrivateBulletinFields } from './UpdateBulletin'
import CustomSelectInput from './CustomSelectInput'
import { BulletinUrgency } from '../Bulletins/BulletinIcons'

import DocumentList from '../Operations/Documents/DocumentList'
import moment from 'moment'
import DOMPurify from 'dompurify'

const formatLiveUntil = d => moment(d).toISOString()

const CreateBulletin = ({ customSubmitButton, onFinish }) => {
  const { add } = useToast()

  const {
    values,
    handleChange,
    handleSelect,
    handleSubmit,
    mergeValues
  } = useForm(createBulletinandPostMessage, {
    users: [],
    tags: [],
    urgency: BulletinUrgency[0],
    liveUntil: moment(new Date())
      .add(1, 'd')
      .startOf('d')
      .toDate()
  })
  const [createBulletin, { loading }] = useMutation(CREATE_BULLETIN, {
    refetchQueries: ['GetBulletinList']
  })

  async function createBulletinandPostMessage() {
    try {
      if (!values.message || !values.subject) {
        add({
          content: 'Please provide a message and subject.',
          color: 'danger'
        })
        return
      }

      const { users, tags, urgency, attachments, ...vals } = values

      const res = await createBulletin({
        variables: {
          ...vals,
          body: DOMPurify.sanitize(vals.body || ''),
          userIds: users.map(u => u.id),
          tagIds: tags.map(u => u.id),
          liveUntil: formatLiveUntil(values.liveUntil),
          urgency: urgency && urgency.value,
          attachmentIds: attachments?.length
            ? attachments.map(f => f.id)
            : []
        }
      })

      if (res.data.createBulletin) {
        add({
          color: 'success',
          content: 'Bulletin Posted'
        })
        onFinish && onFinish()
      } else {
        if (res.errors) {
          add({
            color: 'danger',
            content: cleanGraphQLError(res.errors[0])
          })
        }
      }
    } catch (e) {
      console.log(e)
      add({
        color: 'danger',
        content: cleanGraphQLError(e.message)
      })
    }
  }

  const hasAttachments =
    values.attachments && values.attachments.length

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label>Subject</Label>
        <Input
          required
          id="subject"
          name="subject"
          value={values.subject || ''}
          onChange={handleChange}
          placeholder="Bulletin Subject"
        />
      </FormGroup>
      <FormGroup>
        <Label>Details</Label>
        <CustomRichText>
          <RichTextInput
            required
            id="message"
            name="message"
            minHeight="300px"
            value={values.message}
            placeholder="Bulletin details"
            type="textarea"
            onChange={content => {
              mergeValues({
                message: content
              })
            }}
          />
        </CustomRichText>
      </FormGroup>

      <FormGroup>
        <Label>Documents</Label>
        <ModalButton
          contentSize={'lg'}
          modalHeader="Upload or Attach Files"
          buttonLabel={
            hasAttachments
              ? `${values.attachments.length} attached`
              : 'Click here to attach'
          }
          buttonProps={{
            variant: hasAttachments ? 'warning' : 'secondary',
            sx: {
              marginLeft: '5px',
              boxShadow: 'none'
            }
          }}
          renderModalActions={toggle => (
            <Button
              variant="primary"
              onClick={() => {
                toggle()
              }}
            >
              Done Attaching
            </Button>
          )}
        >
          {({}) => {
            return (
              <>
                <DocumentList
                  take={5}
                  selectedFiles={values.attachments}
                  hideEdit={true}
                  onDeselect={(e, file) => {
                    mergeValues({
                      attachments: values.attachments.filter(
                        i => i.id !== file.id
                      )
                    })
                  }}
                  onSelect={(e, file) => {
                    mergeValues({
                      attachments: [
                        ...(values?.attachments || []),
                        file
                      ]
                    })
                  }}
                />
              </>
            )
          }}
        </ModalButton>
      </FormGroup>
      <FormGroup>
        <Label>Urgency</Label>
        <CustomSelectInput
          id="urgency"
          name="urgency"
          loading={loading}
          style={{ width: '100%' }}
          value={values.urgency}
          withColor
          onChange={selected => {
            mergeValues({
              urgency: selected
            })
          }}
          options={BulletinUrgency}
        />
      </FormGroup>
      <FormGroup>
        <Label>Pin until</Label>
        <DatePickerInput
          id="liveUntil"
          name="liveUntil"
          value={values.liveUntil}
          timeIntervals={5}
          minDate={new Date()}
          onChange={date => {
            handleSelect({
              name: 'liveUntil',
              value: date
            })
          }}
        />
      </FormGroup>
      <FormGroup>
        <Switch
          id={`isPrivate`}
          name="isPrivate"
          label={'Share with specific team members only'}
          checked={values.isPrivate}
          readOnly
          onClick={handleChange}
        />
      </FormGroup>

      {values.isPrivate && (
        <PrivateBulletinFields
          mergeValues={mergeValues}
          values={values}
        />
      )}
      {customSubmitButton({ loading }) || (
        <Button type="submit" disabled={loading}>
          {loading ? 'Creating' : 'Create'}
        </Button>
      )}
    </Form>
  )
}

export default CreateBulletin

export const CreateBulletinModalButton = ({ text, buttonProps }) => {
  return (
    <ModalButton
      modalProps={{ checkOnClose: true }}
      buttonLabel={text || 'New Bulletin +'}
      modalHeader="Create Bulletin"
      buttonProps={{ variant: 'primary', ...buttonProps }}
    >
      {({ toggle }) => {
        return (
          <CreateBulletin
            onFinish={toggle}
            customSubmitButton={({ loading }) => (
              <ModalFooter onClose={toggle}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Posting...' : `Create & Post`}
                </Button>

                {loading && (
                  <div style={{ marginleft: '3px' }}>
                    This may take a few seconds...
                  </div>
                )}
              </ModalFooter>
            )}
          />
        )
      }}
    </ModalButton>
  )
}
