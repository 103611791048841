/** @jsxImportSource theme-ui */
import { useRef, useState } from 'react'
import { useWindowSize } from 'src/styles'
import { Flex } from 'theme-ui'

import Button from './Elements/Button'
import ModalBody from './Elements/ModalBody'
import ModalFooter from './Elements/ModalFooter'
import Modal from './Elements/Modal'

const ModalButton = ({
  buttonLabel,
  children,
  modalHeader,
  buttonProps,
  renderModalActions,
  CustomButtonComponent,
  modalProps = {},
  allowPropagation,
  onClose,
  isValidToggle,
  onOpen,
  bodyStyles,
  blockButton,
  contentWidth,
  contentHeight,
  contentSize,
  disableOpen,
  hideCancel,
  localModalPosition,
  modalIsOpen
}) => {
  const { mobile } = useWindowSize()
  const [modalOpen, setModalOpen] = useState(modalIsOpen || false)
  const ref = useRef()

  if (localModalPosition && !mobile) {
    const bound = ref.current?.getBoundingClientRect()
    modalProps.contentStyles = {
      ...(modalProps?.contentStyles || {}),
      top: bound?.y - (bound?.height + 150),
      left: bound?.x,
      width: '400px',
      ...(localModalPosition === 'right' && {
        left: `${bound?.x + 200}px`,
        right: 'auto'
      }),
      ...(localModalPosition === 'left' && {
        left: `${bound?.x - 200}px`,
        right: 'auto'
      })
    }
  }

  const toggle = e => {
    if (disableOpen) return

    if (!allowPropagation && e) {
      e?.stopPropagation && e?.stopPropagation()
      e?.nativeEvent && e?.nativeEvent.stopImmediatePropagation()
    }

    if (isValidToggle && !modalOpen && !isValidToggle()) {
      return
    }

    if (!modalOpen) {
      onOpen && onOpen()
    }
    if (modalOpen) {
      onClose && onClose()
    }
    setModalOpen(!modalOpen)
  }

  if (disableOpen) {
    buttonProps.style = {
      ...buttonProps.style,
      cursor: 'not-allowed'
    }
  }

  return (
    <Flex sx={{ width: blockButton && '100%' }} ref={ref}>
      {CustomButtonComponent ? (
        <CustomButtonComponent
          onClick={e => {
            if (!allowPropagation) {
              e.stopPropagation()
            }
            toggle(e)
          }}
          style={{ cursor: 'pointer' }}
          {...buttonProps}
        />
      ) : (
        <Button
          type="button"
          onClick={e => {
            if (!allowPropagation) {
              e.stopPropagation()
            }
            toggle(e)
          }}
          sx={{ minWidth: '40px' }}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )}
      <Modal
        isOpen={modalOpen}
        {...modalProps}
        height={contentHeight}
        width={contentWidth}
        size={contentSize}
        modalHeader={modalHeader}
        toggle={toggle}
      >
        {modalOpen && children && (
          <ModalBody
            style={{
              height: '100%',
              ...bodyStyles
            }}
          >
            {children({ open: modalOpen, toggle })}
          </ModalBody>
        )}

        {modalOpen && renderModalActions && (
          <ModalFooter onClose={!hideCancel && toggle}>
            {renderModalActions(toggle)}
          </ModalFooter>
        )}
      </Modal>
    </Flex>
  )
}

export default ModalButton
