import { useState, useEffect } from 'react'

import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'

import CustomSelectInput from './CustomSelectInput'
import { Flex } from 'theme-ui'
import Button from '../Shared/Elements/Button'
import Row from '../Shared/Elements/Row'
import Col from '../Shared/Elements/Col'
import Input from '../Shared/Elements/Input'
import FormGroup from '../Shared/Elements/FormGroup'
import FormText from '../Shared/Elements/FormText'

import Icon from '../Shared/Icon'
import Label from '../Shared/Elements/Label'
import { useToast } from 'src/components/toasts'
import useGetTodaysAssignment from 'src/app/hooks/useGetTodaysAssignment'

const getDefaultRep = isPrimary => {
  return {
    responderId: null,
    responderName: null,
    positionId: null,
    positionName: null,
    primary: isPrimary,
    isLocation: false
  }
}

const DailyAssignmentForm = ({ onFinish }) => {
  const { add } = useToast()
  const [changeLocation, setChangeLocation] = useState(false)
  const {
    positions,
    users,
    locations,
    user: me
  } = useGetCurrentAgencyContext()
  const [
    dailyResponderStrategy,
    setDailyResponderStrategy
  ] = useState(me?.dailyResponderStrategy)
  const {
    data,
    loading,
    updateDailyResponder
  } = useGetTodaysAssignment()
  const [newResps, setNewResp] = useState([])
  const [newLoc, setNewLoc] = useState(null)

  useEffect(() => {
    const hasCachedResps = data?.todaysResponders?.length > 0
    const cachedLocation = data?.todaysLocation

    const defaultResp = getDefaultRep(!hasCachedResps)

    const defaultRespArray = hasCachedResps
      ? data.todaysResponders
      : [defaultResp]

    setNewResp(defaultRespArray)
    if (cachedLocation) {
      setNewLoc({
        id: cachedLocation.locationId,
        name: cachedLocation.locationName
      })
    }
  }, [data])

  const addResponder = () => {
    const currentResps = newResps || []
    const resps = [
      ...currentResps,
      getDefaultRep(newResps && newResps && newResps.length < 1)
    ]

    setNewResp(resps)
  }

  const locationSelected = newResps?.find(r =>
    locations.some(l => l.id === r.positionId)
  )

  const location =
    newLoc ||
    locations?.find(l => l.id === locationSelected?.positionId)

  const updateCache = async (override, locoverride) => {
    try {
      const resps =
        override ||
        newResps.filter(i => i.positionId || i.responderId)

      const l =
        locoverride || !location
          ? null
          : {
              locationId: location?.id,
              locationName: location?.name,
              __typename: 'TodaysLocation'
            }

      await updateDailyResponder({
        todaysResponders: resps,
        location: l,
        dailyResponderStrategy
      })

      onFinish && onFinish()

      setNewResp([])
    } catch (e) {
      console.log(e)
      add({
        content:
          'Error updating. Please refresh the page and try again.',
        color: 'danger'
      })
    }
  }

  const updateResponder = ({ key, ...newData }) => {
    const isLocation = locations.some(
      l => l.id === newData?.positionId
    )
    if (key === 0) {
      setNewLoc(
        isLocation
          ? {
              id: newData.positionId,
              name: newData.positionName
            }
          : null
      )
    }

    setNewResp(
      newResps.map((r, k) => {
        const updates = key === k ? { ...newData } : {}

        return {
          ...r,
          ...updates,
          __typename: 'todaysResponder'
        }
      })
    )
  }

  const togglePrimary = ({ key }) => {
    setNewResp(
      newResps.map((r, k) => {
        const updates =
          key === k ? { primary: !r.primary } : { primary: false }

        return {
          ...r,
          ...updates,
          __typename: 'todaysResponder'
        }
      })
    )
  }

  const clearResponder = ({ key, all }) => {
    setNewResp(all ? [] : newResps.filter((r, k) => key !== k))
  }

  return (
    <div>
      {newResps.map((res, k) => {
        return (
          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              bg: 'gray200',
              p: 10,
              marginBottom: 10,
              flexWrap: 'wrap'
            }}
          >
            <FormGroup
              sx={{
                flex: 1,
                padding: '5px 5px 0',
                minWidth: '200px'
              }}
            >
              <CustomSelectInput
                placeholder="Select responder..."
                options={users}
                value={
                  res.responderId && {
                    name: res.responderName,
                    id: res.responderId
                  }
                }
                getOptionValue={v => v.id}
                getOptionLabel={v => v.name}
                isClearable
                quickSelectTitle={'Select Me'}
                quickSelectFunction={opts => {
                  return (
                    opts?.find &&
                    opts?.find(o => {
                      return o.id === me.id
                    })
                  )
                }}
                onChange={(suggestion, options) => {
                  if (!suggestion) {
                    updateResponder({
                      key: k,
                      responderName: null,
                      responderId: null
                    })
                  } else {
                    updateResponder({
                      responderName: suggestion.name,
                      responderId: suggestion.id,
                      key: k
                    })
                  }
                }}
                defaultValue={
                  res.responderId && {
                    name: res.responderName,
                    id: res.responderId
                  }
                }
              />
            </FormGroup>
            <FormGroup
              sx={{
                flex: 1,
                padding: '5px 5px 0',
                minWidth: '200px'
              }}
            >
              <CustomSelectInput
                placeholder="Select assignment..."
                options={positions.filter(i => !i.locationOnly)}
                getOptionValue={v => v.id}
                getOptionLabel={v => v.name}
                isClearable
                onChange={(suggestion, options) => {
                  if (options.action === 'clear') {
                    updateResponder({
                      key: k,
                      positionName: null,
                      positionId: null
                    })
                  } else {
                    updateResponder({
                      positionName: suggestion.name,
                      positionId: suggestion.id,
                      key: k
                    })
                  }
                }}
                defaultValue={
                  res.positionId && {
                    name: res.positionName,
                    id: res.positionId
                  }
                }
              />
            </FormGroup>
            <Button
              sx={{
                marginTop: '5px'
              }}
              onClick={() => clearResponder({ key: k })}
            >
              <Icon
                icon="close"
                height={15}
                width={15}
                color="white"
              />
            </Button>
            {newResps && newResps.length > 0 && (
              <FormGroup style={{ width: '100%' }}>
                <Input
                  id={'noteTaker' + k}
                  type="checkbox"
                  name="primary"
                  label="Note Taker"
                  onChange={v => togglePrimary({ key: k })}
                  checked={res.primary}
                />
              </FormGroup>
            )}
          </Flex>
        )
      })}

      <Flex
        sx={{
          with: '100%',
          justifyContent: 'stretch',
          mb: 20,
          py: 10
        }}
      >
        <Button onClick={addResponder} variant="secondary">
          Add +
        </Button>
      </Flex>
      <Flex
        sx={{
          with: '100%',
          justifyContent: 'flex-end',
          padding: '5px',
          my: 20
        }}
      >
        {!changeLocation ? (
          <Button
            sx={{ px: '0px' }}
            variant="link"
            onClick={() => setChangeLocation(true)}
          >
            Edit Location ({location?.name || 'Not set'})
          </Button>
        ) : (
          <FormGroup style={{ width: '100%' }}>
            <Label>Location</Label>
            <CustomSelectInput
              options={locations}
              getOptionValue={v => v.id}
              getOptionLabel={v => v.name}
              isClearable
              value={location || null}
              onChange={selected => {
                setNewLoc(selected)
              }}
              defaultValue={location || null}
            />
          </FormGroup>
        )}
      </Flex>
      <Flex sx={{ flexDirection: 'column', gap: '5px', my: '20px' }}>
        <label>
          <input
            type="checkbox"
            checked={dailyResponderStrategy === 'RESET'}
            onChange={() =>
              setDailyResponderStrategy(
                dailyResponderStrategy === 'RESET' ? '' : 'RESET'
              )
            }
          />
          Reset Daily
        </label>
        <label>
          <input
            type="checkbox"
            checked={dailyResponderStrategy === 'AUTO'}
            onChange={() =>
              setDailyResponderStrategy(
                dailyResponderStrategy === 'AUTO' ? '' : 'AUTO'
              )
            }
          />
          Remember My Assignment
        </label>
      </Flex>
      <Row>
        <Col>
          <Button
            variant="secondary"
            onClick={async () => {
              updateCache([], true)
            }}
            block
          >
            Clear All
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            onClick={() => {
              updateCache()
            }}
            disabled={loading}
            icon={loading && 'spinner'}
            block
          >
            Save and Close
          </Button>
        </Col>
      </Row>
      <FormText style={{ textAlign: 'center', marginTop: '10px' }}>
        This will update your daily position and name. <br /> All
        incidents submitted will use this information.
      </FormText>
    </div>
  )
}

export default DailyAssignmentForm
