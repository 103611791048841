import config from 'src/config'

import { setContext } from '@apollo/client/link/context'

import { RetryLink } from '@apollo/client/link/retry'
import { resolvers, typeDefs } from '../state'

import fetch from 'isomorphic-fetch'

import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { ApolloLink, HttpLink, split } from '@apollo/client'

import localForage from 'localforage'

// import { RouteTree } from '../Router'
import { stytchClient } from './Stytch'

function getURI() {
  // skip Stellate
  // if (
  //   operation.operationName === 'GetDispatchData' ||
  //   operation.operationName === 'GetDispatchPositions'
  // ) {
  //   console.log('getURI: ', config.GRAPHQL_ORIGIN_URL)
  //   return config.GRAPHQL_ORIGIN_URL
  // }

  // All other operations to stellate
  return config.GRAPHQL_URL
}

const batchLink = new BatchHttpLink({ uri: getURI })
const httpLink = new HttpLink({ uri: getURI })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookie if it exists
  const token = stytchClient?.session?.getTokens()?.session_jwt
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // debugging stellate cache in staging
      // ...(config.stage !== 'production' && { 'gcdn-debug': 1 }),
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const retry = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 1
  }
})

export const persistorOptions = {
  storage: localForage,
  maxSize: 512000 * 2
}

const checkForTokenExpiredError = graphQLErrors => {
  return (
    graphQLErrors &&
    graphQLErrors.find(e => {
      return (
        e?.extensions === 'UNAUTHENTICATED' ||
        e.message.includes('Session could not be found') ||
        e.message.includes('Not Author') ||
        e.extensions?.exception?.stacktrace?.[0]?.startsWith(
          'TokenExpiredError'
        ) ||
        e.extensions?.stacktrace?.[0]?.startsWith('TokenExpiredError')
      )
    })
  )
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const tokenExpiredError = checkForTokenExpiredError(graphQLErrors)

  if (tokenExpiredError) {
    console.log('Not authed! Logging Out')
    window.location.search = '?expired=true'
  }

  if (networkError?.message.includes('Failed to fetch')) {
    console.log("Can't connect to server")
    networkError.message =
      'Sorry this feature is not available in offline mode.'
  }
})

const disabledBatchQueries = [
  'CurrentAgency',
  'Me',
  'GetChatToken',
  'GetMyAgencyPositionStatuses',
  'GetBulletins',
  'GetDispatchData',
  'GetNwsAlerts',
  'AgencySubCount',
  'GetPlatformNotification',
  'GetUptimeStatus',
  'GetRecentPulseNotifications',
  'GetWeatherData'
]

const batchQuerySplit = split(
  operation => disabledBatchQueries.includes(operation.operationName),
  httpLink,
  batchLink
)

const splitLink = ApolloLink.from([
  authLink,
  errorLink,
  retry,
  batchQuerySplit
])

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  },
  query: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
}
const configOptions = {
  link: splitLink,
  typeDefs,
  resolvers,
  fetch,
  devtools: {
    enabled: true
  },
  defaultOptions
}

export default configOptions
