import React from 'react'
import Button from './Elements/Button'
import { Flex } from 'theme-ui'

export const OrderByButtons = ({ setSort, sort }) => {
  return (
    <Flex>
      <Button
        size="sm"
        iconPosition="right"
        variant="naked"
        icon={
          sort === 'newest'
            ? 'up arrow'
            : sort === 'oldest'
            ? 'down arrow'
            : ''
        }
        title="Sort by date"
        iconSize={10}
        onClick={() =>
          setSort(sort === 'newest' ? 'oldest' : 'newest')
        }
      >
        Created At
      </Button>
      <Button
        size="sm"
        variant="naked"
        iconSize={10}
        title="Sort by name"
        iconPosition="right"
        icon={
          sort === 'az'
            ? 'up arrow'
            : sort === 'za'
            ? 'down arrow'
            : ''
        }
        onClick={() => setSort(sort === 'az' ? 'za' : 'az')}
      >
        A-Z
      </Button>
    </Flex>
  )
}
