import { memo, useEffect, useRef, useState } from 'react'

import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import { Flex, Box } from 'theme-ui'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import useForm from 'src/app/hooks/useForms'

import { useToast } from 'src/components/toasts'
import { createActivtyTypeLabel } from 'src/utils/dataMapping'
import handleSortOrder from 'src/utils/handleSortOrder'
import { CenteredContent, Space } from '..'
import CustomRadioButtonsInput from '../Forms/CustomRadioButtonsInput'

import DatePickerInput from '../Forms/DatePickerInput'
import FormError, { cleanGraphQLError } from '../Forms/FormError'

import InlineTooltip from '../Shared/InlineTooltip'

import ModalButton from '../Shared/ModalButton'
import { QuickSurvey } from './QuickSurvey'
import StickyButton from '../Shared/StickyButton'
import Label from '../Shared/Elements/Label'
import Button from '../Shared/Elements/Button'
import FormGroup from '../Shared/Elements/FormGroup'
import Form from '../Shared/Elements/Form'
import Input from '../Shared/Elements/Input'
import FormText from '../Shared/Elements/FormText'

import CustomSelectInput from '../Forms/CustomSelectInput'
import get from 'lodash/get'

import { usePromptOnNavigate } from 'src/app/hooks/usePromptOnNavigate'
import { useSubmitBulkSubmission } from 'src/app/hooks/useSubmitBulkSubmission'
import { prettyDate } from 'src/utils'
import useGetTodaysAssignment from 'src/app/hooks/useGetTodaysAssignment'

const totalVictimsOptions = [
  { value: 1 },
  { value: 5 },
  { value: 10 },
  { value: 50 },
  { value: 75 },
  { value: 100 },
  { value: 150 },
  { value: 200 },
  { value: 250 }
]

const getTotalVictimOptions = num => {
  const indexExists = totalVictimsOptions.find(o => o.value === num)
  const closestIndex = num
    ? totalVictimsOptions.findIndex(o => o.value >= num)
    : 0

  let newArray = totalVictimsOptions.slice(
    closestIndex,
    closestIndex + 4
  )

  if (indexExists || typeof num !== 'number') {
    // newArray[0].default = true
    return newArray
  }

  return [
    { value: num || totalVictimsOptions[0].value },
    ...newArray.slice(1)
  ]
}

const checkAllValuesAreFalse = obj =>
  Object.values(obj).every(value => value === false)

function getErrorKey(name, id) {
  return name + '_' + id
}

const BulkIncident = memo(
  ({ onFinish, hideResponderInputs, hideDateInput, stepId }) => {
    const today = new Date().toISOString()
    const { data: todaysAssignment } = useGetTodaysAssignment()
    const responders = get(todaysAssignment, 'todaysResponders', [{}])
    const todaysLoc = get(todaysAssignment, 'todaysLocation', null)
    const [changeDate, setChangeDate] = useState(false)
    const [changeLocation, setChangeLocation] = useState(false)
    const ref = useRef()
    const {
      currentAgency,
      allPositions,
      locations,
      users,
      positions,
      user: me
    } = useGetCurrentAgencyContext()
    const [bulkActivityTypes, setBulkActivityTypes] = useState(
      getOrderedBulkCategories(
        currentAgency ? currentAgency.activities : []
      )
    )
    const { submitBulkIncident, loading } = useSubmitBulkSubmission()

    const { add } = useToast()
    const {
      values,
      errors,
      setValues,
      handleChange,
      handleSubmit,
      isDirty
    } = useForm(onSubmit, {
      incidentDate: today,
      skipAssignment: false,
      location: allPositions?.find(
        l => l.id === todaysLoc?.locationId
      ),
      resources: mapRespsToOptions(responders),
      stepId
    })

    usePromptOnNavigate({
      message:
        'Are you sure you want to leave this page? You may lose unsaved data.',
      path: '/agency/submission',
      skip: !isDirty
    })

    function mapRespsToOptions(resps) {
      return resps.length > 0
        ? resps.map(r => ({
            ...r,
            ...(r.positionId && {
              positionAutoFilled: true
            }),
            ...(r.responderId && {
              responderAutoFilled: true
            })
          }))
        : [{}]
    }

    useEffect(() => {
      if (isEmpty(responders?.[0]) || values.skipAssignment) {
        return
      } else {
        setValues({
          ...values,
          skipAssignment: false,
          resources: mapRespsToOptions(responders)
        })
      }

      if (todaysLoc) {
        setValues({
          ...values,
          location: allPositions?.find(
            l => l.id === todaysLoc?.locationId
          )
        })
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responders, todaysLoc])

    const resourceCount = values.resources?.filter(
      r => r?.positionId || r?.responderId
    )?.length

    function getOrderedBulkCategories(activities) {
      return handleSortOrder(
        activities
          .filter(a => a.pinToBulkIncident)
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    }

    function setBulkIncidents() {
      setBulkActivityTypes(
        getOrderedBulkCategories(currentAgency.activities)
      )
    }

    useEffect(() => {
      setBulkIncidents()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAgency])

    function handleIncidentChange(input, id) {
      setValues({
        ...values,
        data: {
          ...values.data,
          [id]: {
            ...(values.data && values.data[id]
              ? values.data[id]
              : {}),
            [input.name]: input.value
          }
        }
      })
    }

    const locationSelected = values?.resources?.find(r =>
      allPositions.some(l => l.id === r.positionId)
    )

    const location =
      values?.location ||
      allPositions?.find(l => l.id === locationSelected?.positionId)

    function scrollToTop() {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }

    async function onSubmit() {
      try {
        if (resourceCount < 1 && !values.skipAssignment) {
          add({
            content:
              'Please choose an assignment or select the skip assignment box above.',
            color: 'danger'
          })
          return
        }

        const submissionData = Object.entries(values.data)
          .map(([key, value]) => {
            // const ti = parseInt(value.totalIncidents)
            const tc = parseInt(value.totalCount)

            return {
              totalCount: parseInt(tc),
              totalIncidents: 1,
              activityTypeId: key
            }
          })
          .filter(s => s.totalCount > 0)

        const res = await submitBulkIncident({
          variables: {
            incidentDate: values.incidentDate,
            data: submissionData,
            notes: values.notes,
            stepId: values.stepId,
            resources: values.resources
              .filter(r => r.positionId || r.responderId)
              .map(r => ({
                positionId: r.positionId,
                responderId: r.responderId
              })),
            locationId: location?.id
          }
        })

        function onSubmitFinish() {
          if (onFinish) {
            onFinish()
          } else {
            setBulkIncidents()
            setValues({
              ...values,
              data: [],
              incidentDate: today,
              notes: ''
            })
            setChangeDate(false)
            scrollToTop()
          }
        }

        if (res && !res?.errors) {
          add({
            color: 'success',
            content: res.offline
              ? 'Stored Offline Successfully'
              : 'Bulk Incident Submitted!'
          })
          onSubmitFinish()
        } else {
          add({
            content: cleanGraphQLError(res?.errors[0]),
            color: 'danger'
          })
        }
      } catch (e) {
        console.log(e)
        add({
          color: 'danger',
          content: cleanGraphQLError(e)
        })
      }
    }

    const updateResponder = ({ key, ...newData }) => {
      setValues({
        ...values,
        resources: values.resources.map((r, k) => {
          const updates = key === k ? { ...newData } : {}

          return {
            ...r,

            ...updates,
            __typename: 'todaysResponder'
          }
        })
      })
    }

    const clearResponder = ({ key, all }) => {
      setValues({
        ...values,
        resources: all
          ? []
          : values.resources.filter((r, k) => key !== k)
      })
    }

    const addResponder = () => {
      const currentResps = values.resources || []
      const resps = [...currentResps, {}]

      setValues({
        ...values,
        resources: resps
      })
    }

    const noIncidents =
      !values.data ||
      isEmpty(values.data) ||
      Object.values(values.data).every(x => !x.totalCount)

    const positionIsSet = resourceCount > 0
    return (
      <Box sx={{ position: 'relative' }}>
        <Space height={20} ref={ref} />
        {!bulkActivityTypes || bulkActivityTypes.length < 1 ? (
          <CenteredContent
            sx={{ textAlign: 'center' }}
            height="200px"
          >
            <h4>No bulk categories configured.</h4>
            To add incident categories to this screen go to your
            agency settings.
            <Link to="/agency/profile/stat-categories">
              <Button variant="primary" style={{ marginTop: '20px' }}>
                Click Here to Edit
              </Button>
            </Link>
          </CenteredContent>
        ) : (
          <>
            {!hideResponderInputs && (
              <Flex
                sx={{
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  width: '100%',
                  pt: '20px',
                  bg: 'gray200',
                  p: '10px',
                  position: 'relative'
                }}
              >
                {!positionIsSet && (
                  <InlineTooltip
                    tip={
                      'Use the dropdowns below for quick selection or use the daily responder button on the top right to save your position for future submissions.'
                    }
                    header={'Set your position!'}
                    variant="warning"
                  />
                )}
                {values.resources.map((res, k) => {
                  return (
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        marginTop: k > 0 ? '10px' : '5px'
                      }}
                      key={k}
                    >
                      <FormGroup
                        style={{
                          flex: 1,
                          padding: 5,
                          paddingBottom: 0,
                          marginBottom: 0,
                          position: 'relative'
                        }}
                      >
                        <Label>Responder</Label>
                        <CustomSelectInput
                          options={users}
                          getOptionValue={v => v.id}
                          getOptionLabel={v => v.name}
                          isClearable
                          isDisabled={values.skipAssignment}
                          quickSelectTitle={'Select Me'}
                          value={
                            res.responderName &&
                            res.responderId && {
                              name: res.responderName,
                              id: res.responderId
                            }
                          }
                          quickSelectFunction={opts => {
                            return (
                              opts?.find &&
                              opts?.find(o => {
                                return o.id === me.id
                              })
                            )
                          }}
                          onChange={(suggestion, options) => {
                            if (options?.action === 'clear') {
                              updateResponder({
                                key: k,
                                responderName: null,
                                responderAutoFilled: false,
                                responderId: null
                              })
                            } else {
                              updateResponder({
                                responderName: suggestion.name,
                                responderId: suggestion.id,
                                responderAutoFilled: false,
                                key: k
                              })
                            }
                          }}
                          defaultValue={{
                            name: res.responderName,
                            id: res.responderId
                          }}
                        />

                        <Box
                          sx={{
                            textAlign: 'center',
                            height: '24px',
                            right: 50,
                            left: 50,
                            zIndex: 0,
                            position: 'absolute',
                            bottom: '-5px'
                          }}
                        >
                          {res && res.responderAutoFilled && (
                            <FormText>AUTO-FILLED</FormText>
                          )}
                        </Box>
                      </FormGroup>
                      <FormGroup
                        style={{
                          flex: 1,
                          padding: 5,
                          paddingBottom: 0,
                          position: 'relative',
                          marginBottom: 0
                        }}
                      >
                        <Label>Assignment</Label>
                        <CustomSelectInput
                          options={positions.filter(
                            i => !i.locationOnly
                          )}
                          isDisabled={values.skipAssignment}
                          getOptionValue={v => v.id}
                          getOptionLabel={v => v.name}
                          isClearable
                          value={
                            res.positionId &&
                            res.positionName && {
                              name: res.positionName,
                              id: res.positionId
                            }
                          }
                          onChange={(suggestion, options) => {
                            if (options.action === 'clear') {
                              updateResponder({
                                key: k,
                                positionName: null,
                                positionId: null,
                                positionAutoFilled: false
                              })
                            } else {
                              updateResponder({
                                positionName: suggestion.name,
                                positionId: suggestion.id,
                                positionAutoFilled: false,
                                key: k
                              })
                            }
                          }}
                          defaultValue={{
                            name: res.positionName,
                            id: res.positionId
                          }}
                        />

                        <Box
                          sx={{
                            textAlign: 'center',
                            height: '24px',
                            right: 50,
                            left: 50,
                            zIndex: 0,
                            position: 'absolute',
                            bottom: '-26px'
                          }}
                        >
                          {res && res.positionAutoFilled && (
                            <FormText>AUTO-FILLED</FormText>
                          )}
                        </Box>
                      </FormGroup>
                      {resourceCount > 1 && (
                        <Button
                          sx={{ marginTop: '30px', marginLeft: 1 }}
                          onClick={() => clearResponder({ key: k })}
                          icon="close"
                        />
                      )}
                    </Flex>
                  )
                })}

                {resourceCount > 0 && (
                  <Flex
                    sx={{
                      with: '100%',
                      justifyContent: 'flex-end',
                      mb: 20,
                      py: 10
                    }}
                  >
                    <Button
                      disabled={values.skipAssignment}
                      onClick={addResponder}
                      variant="secondary"
                    >
                      Add Another +
                    </Button>
                  </Flex>
                )}
                <Flex
                  sx={{
                    with: '100%',
                    justifyContent: 'flex-end',
                    padding: '5px',
                    my: 20
                  }}
                >
                  {!changeLocation ? (
                    <Button
                      sx={{ px: '0px' }}
                      variant="link"
                      onClick={() => setChangeLocation(true)}
                    >
                      Edit Location ({location?.name || 'Not set'})
                    </Button>
                  ) : (
                    <FormGroup style={{ width: '100%' }}>
                      <Label>Location</Label>
                      <CustomSelectInput
                        options={locations}
                        isDisabled={values.skipAssignment}
                        getOptionValue={v => v.id}
                        getOptionLabel={v => v.name}
                        isClearable
                        value={location || null}
                        onChange={(selected, options) => {
                          setValues({
                            ...values,
                            location: selected
                          })
                        }}
                        defaultValue={location || null}
                      />
                    </FormGroup>
                  )}
                </Flex>
                {resourceCount === 0 && (
                  <FormGroup sx={{ mb: '0px' }} check>
                    <Input
                      type="checkbox"
                      name="skipAssignment"
                      onChange={handleChange}
                      value={values.skipAssignment}
                      label="Skip setting this step"
                    />
                  </FormGroup>
                )}
              </Flex>
            )}

            <Form
              onSubmit={handleSubmit}
              style={{ paddingBottom: '500px' }}
            >
              <Box sx={{ py: '20px', borderBottom: 'default' }}>
                <FormGroup>
                  <LargeLabel>Notes / Narrative</LargeLabel>
                  <Input
                    style={{ maxWidth: '100%', minWidth: '100%' }}
                    type="textarea"
                    onChange={handleChange}
                    name="notes"
                    id="notes"
                    value={values.notes}
                    placeholder="Notes..."
                  />
                </FormGroup>
                {!hideDateInput && (
                  <>
                    {!changeDate ? (
                      <Button
                        sx={{ px: '0px' }}
                        variant="link"
                        onClick={() => setChangeDate(true)}
                      >
                        Edit Date (
                        {prettyDate(
                          values.incidentDate,
                          'MMM Do YYYY'
                        )}
                        )
                      </Button>
                    ) : (
                      <FormGroup>
                        <LargeLabel>
                          Day you are submitting for?
                        </LargeLabel>
                        <DatePickerInput
                          onChange={date => {
                            setValues({
                              ...values,
                              incidentDate: date
                            })
                          }}
                          maxDate={new Date()}
                          showTimeSelect={false}
                          name="incidentDate"
                          id="incidentDate"
                          value={values.incidentDate}
                          placeholder="Date of incidents..."
                        />
                      </FormGroup>
                    )}
                  </>
                )}
              </Box>

              {bulkActivityTypes.map((a, k) => {
                return (
                  <BulkIncidentInputGroup
                    key={k}
                    type={a}
                    errors={errors}
                    values={
                      values && values.data && values.data[a.id]
                    }
                    handleIncidentChange={handleIncidentChange}
                  />
                )
              })}

              <ModalButton
                buttonProps={{
                  variant: 'link',
                  style: {
                    marginLeft: '5px',
                    padding: '0px'
                  }
                }}
                buttonLabel="Don't see the category? Click here."
              >
                {({ toggle }) => (
                  <CustomBulkIncidentAction
                    currentAgency={currentAgency}
                    onFinish={toggle}
                    addStatCategory={(stat, val) => {
                      setBulkActivityTypes([
                        ...bulkActivityTypes,
                        stat
                      ])
                      handleIncidentChange(
                        {
                          name: 'totalCount',
                          value: val
                        },
                        stat.id
                      )
                    }}
                  />
                )}
              </ModalButton>
              <FormGroup sx={{ mt: 20 }}>
                <LargeLabel>Notes / Narrative</LargeLabel>
                <Input
                  style={{ maxWidth: '100%', minWidth: '100%' }}
                  type="textarea"
                  onChange={handleChange}
                  name="notes"
                  id="notes"
                  value={values.notes}
                  placeholder="Notes..."
                />
              </FormGroup>
              <Space height={120} />
              <Button onClick={scrollToTop}>Back to top</Button>
              <StickyButton
                variant={!isEmpty(values) ? 'danger' : 'secondary'}
                stickySubmit={true}
                disabled={noIncidents || loading}
                preButtonText={
                  !isEmpty(errors) &&
                  !checkAllValuesAreFalse(errors) && (
                    <Flex sx={{ justifyContent: 'center' }}>
                      <FormError
                        error={'Please fix all errors above.'}
                      />
                    </Flex>
                  )
                }
                type="submit"
                size="lg"
                icon={loading && 'spinner'}
              >
                Submit
              </StickyButton>
            </Form>
          </>
        )}
      </Box>
    )
  }
)

const CustomBulkIncidentAction = ({
  currentAgency,
  addStatCategory,
  onFinish,
  ...props
}) => {
  return (
    <>
      <QuickSurvey
        stickySubmit={false}
        typeOnlyWithPad
        hideResetForm
        includeHiddenStats
        numPadTitle={<h5>How many incidents are you reporting?</h5>}
        hideInvolvedParty={true}
        extendedQuickSurvey={false}
        submitButtonText={'Add to Submission'}
        customOnSubmit={e => {
          const { subLabelOne, subLabelTwo, subLabelThree } = e
          const label = createActivtyTypeLabel(
            subLabelOne,
            subLabelTwo,
            subLabelThree
          )

          addStatCategory(
            currentAgency.activities.find(a => a.label === label),
            e[`quickStats${label}`]
          )
          onFinish && onFinish()
        }}
        {...props}
      />
    </>
  )
}

const numberPadStyle = {
  style: {
    minWidth: '60px',
    padding: '8px'
  },
  size: 'lg'
}

const BulkIncidentInputGroup = ({
  type,
  values = {},
  errors,
  handleIncidentChange,
  ...rest
}) => {
  const ref = useRef()
  const victimOpts = getTotalVictimOptions(values.totalIncidents)

  const totalCountError = errors[getErrorKey('totalCount', type.id)]

  return (
    <Flex
      sx={{
        borderBottom: 'default',
        my: '20px',
        py: '20px'
      }}
      ref={ref}
      {...rest}
    >
      <Box sx={{ width: '100%' }}>
        <LargeLabel>{type.label}</LargeLabel>

        <Flex
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}
        >
          <Box>
            <Label style={{ margin: '0px', fontSize: '12px' }}>
              Total Victims / Involved
            </Label>
            <CustomRadioButtonsInput
              type={'number'}
              placeholder={'Other...'}
              name={'totalCount'}
              containerStyles={{ flexWrap: 'none' }}
              buttonStyles={{
                width: '60px',
                padding: '8px'
              }}
              onChange={e =>
                handleIncidentChange(
                  { name: 'totalCount', value: e.target.value },
                  type.id
                )
              }
              handleSelect={e => {
                handleIncidentChange(
                  {
                    name: 'totalCount',
                    value:
                      e.value === values.totalCount ? null : e.value
                  },
                  type.id
                )
              }}
              value={values.totalCount || ''}
              max="20"
              options={victimOpts}
              withNumberPad
              withNumberPadButtonProps={numberPadStyle}
            />
            {totalCountError && <FormError error={totalCountError} />}
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

const LargeLabel = ({ children }) => {
  return (
    <Box sx={{ width: '100%', mb: '15px' }}>
      <h5>{children}</h5>
    </Box>
  )
}

export default BulkIncident
