import { useState } from 'react'
import CustomSelectInput from './CustomSelectInput'
import CustomRadioButtonsInput from './CustomRadioButtonsInput'
import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import Input from '../Shared/Elements/Input'
import FormGroup from '../Shared/Elements/FormGroup'
import ModalFooter from '../Shared/Elements/ModalFooter'

export const vehicleTypeOptions = [
  { label: 'Boat', value: 'BOAT' },
  { label: 'Car', value: 'CAR' },
  { label: 'Truck', value: 'TRUCK' },
  { label: 'Plane', value: 'PLANE' },
  { label: 'Motorcycle', value: 'MOTORCYCLE' },
  { label: 'Powerboat', value: 'POWERBOAT' },
  {
    label: 'Sailboat',
    value: 'SAILBOAT'
  },
  { label: 'Other', value: 'OTHER' }
]

const AddVehicleInputs = ({ onAdd, onClose, buttonOptions }) => {
  const [inputValues, setInputValues] = useState({ passengers: 1 })

  const handleInputChange = e => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    })
  }

  const handleSelectChange = (selected, e) => {
    setInputValues({
      ...inputValues,
      [e.name]: selected
    })
  }

  const handleRadioChange = selected => {
    setInputValues({
      ...inputValues,
      [selected.name]: selected.value
    })
  }

  return [
    <FormGroup key={0}>
      <Label>CF/License Number</Label>
      <Input
        onChange={handleInputChange}
        name="lNumber"
        id="lNumber"
        value={inputValues.lNumber || ''}
      />
    </FormGroup>,
    <FormGroup key={1}>
      <Label>Passengers</Label>
      <CustomRadioButtonsInput
        id="passengers"
        type="number"
        name="passengers"
        onChange={handleInputChange}
        value={inputValues.passengers}
        handleSelect={handleRadioChange}
        minValue={0}
        options={[
          { value: 0 },
          { value: 1, default: true },
          { value: 2 },
          { value: 3 },
          { value: 4 }
        ]}
        withInput
      />
    </FormGroup>,
    <FormGroup key={2}>
      <Label htmlFor="sort">Type *</Label>
      <CustomSelectInput
        id="vehicleType"
        name="vehicleType"
        value={inputValues.vehicleType || ''}
        onChange={handleSelectChange}
        options={vehicleTypeOptions}
      />
    </FormGroup>,
    <FormGroup inline key={4}>
      <Label htmlFor="sort">Value</Label>
      <CustomRadioButtonsInput
        id="vehicleValue"
        type="number"
        name="vehicleValue"
        onChange={handleInputChange}
        value={inputValues.vehicleValue}
        handleSelect={handleRadioChange}
        minValue={0}
        options={[
          { label: '0-20k', value: 10000 },
          { label: '20-50k', value: 35000 },
          { label: '50-100k', value: 75000 },
          { label: '100-500k', value: 125000 },
          { label: '500k+', value: 500000 }
        ]}
        withInput
      />
    </FormGroup>,
    <ModalFooter onClose={onClose}>
      <Button
        key={5}
        onClick={e => {
          e.preventDefault()
          onAdd(inputValues)
        }}
        {...buttonOptions}
        variant={inputValues.vehicleType && 'primary'}
      >
        {(buttonOptions && buttonOptions.label) || 'Save Vehicle'}
      </Button>
    </ModalFooter>
  ]
}

export default AddVehicleInputs
