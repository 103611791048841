import { Box, Flex, Select } from 'theme-ui'

import CustomSelectInput from 'src/app/components/Forms/CustomSelectInput'
import { DateRangePicker } from 'src/app/components/Forms/DatePickerInput'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'

import { StatefulGoogleInput } from 'src/app/components/Forms/GoogleMapInput'
import Input from 'src/app/components/Shared/Elements/Input'

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const handleFloatVsText = (type, val) => {
  return type === 'number' ? parseFloat(val) : val
}

const RelationMultiDropdown = ({
  value,
  type,
  onChange,
  ...props
}) => {
  const currentAgency = useGetCurrentAgencyContext()

  return (
    <CustomSelectInput
      {...props}
      closeMenuOnSelect
      value={value}
      options={currentAgency[type]?.map(v => ({
        id: v.id,
        name: v.name
      }))}
      getOptionValue={v => v.id}
      getOptionLabel={v => v.name}
      isClearable
      isMulti={true}
      onChange={onChange}
    />
  )
}

const RelationInputBranch = props => {
  return (
    <Box
      sx={{
        width: '250px',
        maxWidth: '250px',
        flex: 1
      }}
    >
      {props.allowOther}
      {props.type === 'text' || props.type === 'number' ? (
        props.allowedValues?.length > 0 ? (
          <CustomSelectInput
            {...props}
            value={
              Array.isArray(props.value) && props.value.length > 0
                ? props.value?.map(v => ({
                    label: v,
                    value: v
                  }))
                : props.value && {
                    label: props.value,
                    value: props.value
                  }
            }
            creatable={props.allowOther}
            getNewOptionData={(inputValue, optionLabel) => {
              return {
                label: optionLabel,
                value: inputValue,
                __isNew__: true
              }
            }}
            onChange={e => {
              if (Array.isArray(e)) {
                props.onChange(e.map(v => v.value))
              } else {
                props.onChange(e.value)
              }
            }}
            options={props.allowedValues.map(v => ({
              label: v,
              value: v
            }))}
          />
        ) : (
          <Input
            {...props}
            placeholder={`Enter a ${props.type} value`}
            onChange={e => props.onChange(e.target.value)}
          />
        )
      ) : props.type === 'locations' ? (
        <StatefulGoogleInput
          {...props}
          showPrettyAddress
          handleSelect={address => props.onChange(address)}
        />
      ) : (
        <RelationMultiDropdown {...props} />
      )}
    </Box>
  )
}

const DateRangePickerInput = ({ value, onChange }) => {
  return (
    <Flex>
      <DateRangePicker
        id="range"
        value={value || {}}
        onChange={onChange}
      />
    </Flex>
  )
}

export const operators = [
  {
    label: 'equals',
    value: 'equals',
    renderWhereValueComponent: props => (
      <RelationInputBranch isMulti={false} {...props} />
    ),
    requiresValue: true,
    relationMapToWhereArg: (val, key) => ({
      [key]: {
        AND: [
          {
            every: {
              id: {
                in: val.filter(v => v.id).map(v => v.id)
              }
            }
          },
          { some: {} }
        ]
      }
    }),
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              equals: handleFloatVsText(
                key,
                Array.isArray(val) ? val[0] : val
              )
            }
          }
          //handle multi text or multi number
        ].concat(
          key === 'text' || key === 'number'
            ? [
                {
                  [`multi${capitalize(key)}`]: Array.isArray(val)
                    ? {
                        equals: val?.map(val =>
                          handleFloatVsText(key, val)
                        )
                      }
                    : { equals: [handleFloatVsText(key, val)] }
                }
              ]
            : []
        )
      }
    }
  },
  {
    label: 'does not equal',
    value: 'not_equal',
    renderWhereValueComponent: props => (
      <RelationInputBranch isMulti={false} {...props} />
    ),
    relationMapToWhereArg: (val, key) => ({
      [key]: { none: {} }
    }),
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              not: {
                equals: handleFloatVsText(
                  key,
                  Array.isArray(val) ? val[0] : val
                )
              }
            }
          }
          //handle multi text or multi number
        ].concat(
          key === 'text' || key === 'number'
            ? [
                {
                  NOT: {
                    [`multi${capitalize(key)}`]: Array.isArray(val)
                      ? {
                          equals: val?.map(val =>
                            handleFloatVsText(key, val)
                          )
                        }
                      : { has: handleFloatVsText(key, val) }
                  }
                }
              ]
            : []
        )
      }
    }
  },
  {
    label: 'exists',
    value: 'exists',
    relationMapToWhereArg: (val, key) => ({
      [key === 'file' ? 'files' : key]: { some: {} }
    }),

    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              not: null
            }
          }
          //handle multi text or multi number
        ].concat(
          key === 'text' || key === 'number'
            ? [
                {
                  [`multi${capitalize(key)}`]: {
                    isEmpty: false
                  }
                }
              ]
            : []
        )
      }
    }
  },
  {
    label: 'does not exist',
    value: 'not_exists',
    relationMapToWhereArg: (val, key) => ({
      [key === 'file' ? 'files' : key]: { none: {} }
    }),
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              equals: null
            }
          }
          //handle multi text or multi number
        ].concat(
          key === 'text' || key === 'number'
            ? [
                {
                  [`multi${capitalize(key)}`]: {
                    isEmpty: true
                  }
                }
              ]
            : []
        )
      }
    }
  },
  {
    label: 'is true',
    value: 'is_true',
    relationMapToWhereArg: (val, key) => ({
      [key]: { some: {} }
    }),
    mapToWhereArg: (val, key) => {
      return {
        [key]: {
          equals: true
        }
      }
    }
  },
  {
    label: 'is false',
    value: 'is_false',
    relationMapToWhereArg: (val, key) => ({
      [key]: { none: {} }
    }),
    mapToWhereArg: (val, key) => {
      return {
        [key]: {
          equals: false
        }
      }
    }
  },
  {
    label: 'is greater than',
    value: 'greater_than',
    tip:
      'Number comparisons will not work with answers that have multi-select.',
    requiresValue: true,
    renderWhereValueComponent: props => (
      <RelationInputBranch {...props} />
    ),
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              gt: parseFloat(val)
            }
          }

          //handle multi text or multi number
        ]
      }
    }
  },
  {
    label: 'is less than',
    value: 'less_than',
    tip:
      'Number comparisons will not work with answers that have multi-select.',
    requiresValue: true,
    renderWhereValueComponent: props => (
      <RelationInputBranch {...props} />
    ),
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              lt: parseFloat(val)
            }
          }
        ]
      }
    }
  },
  {
    label: 'is greater than equal to',
    value: 'greater_than_equal_to',
    tip:
      'Number comparisons will not work with answers that have multi-select.',
    requiresValue: true,
    renderWhereValueComponent: props => (
      <RelationInputBranch {...props} />
    ),
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              gte: parseFloat(val)
            }
          }

          //handle multi text or multi number
        ]
      }
    }
  },
  {
    label: 'is less than equal to',
    value: 'less_than_equal_to',
    tip:
      'Number comparisons will not work with answers that have multi-select.',
    requiresValue: true,
    renderWhereValueComponent: props => (
      <RelationInputBranch {...props} />
    ),
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              lte: parseFloat(val)
            }
          }
        ]
      }
    }
  },
  {
    label: 'contains',
    value: 'contains',
    requiresValue: true,
    renderWhereValueComponent: props => (
      <RelationInputBranch
        isMulti={false}
        {...props}
        allowedValues={
          props.allowMultipleAnswers && props.allowedValues
        }
      />
    ),
    relationMapToWhereArg: (val, key) =>
      key === 'locations'
        ? {
            [key]: {
              some: {
                OR: [
                  {
                    prettyAddress: {
                      contains: val.prettyAddress
                    }
                  },
                  {
                    AND: [
                      {
                        lat: {
                          equals: val.lat
                        }
                      },
                      {
                        lng: {
                          equals: val.lng
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        : {
            [key]: {
              some: {
                id: {
                  in: val.filter(v => v.id).map(v => v.id)
                }
              }
            }
          },
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: Array.isArray(val)
              ? {
                  in: val.map(v => handleFloatVsText(key, v))
                }
              : {
                  contains: handleFloatVsText(key, val),
                  mode: 'insensitive'
                }
          }
          //handle multi text or multi number
        ].concat(
          key === 'text' || key === 'number'
            ? [
                {
                  [`multi${capitalize(key)}`]: Array.isArray(val)
                    ? {
                        hasSome: val?.map(val =>
                          handleFloatVsText(key, val)
                        )
                      }
                    : { has: handleFloatVsText(key, val) }
                }
              ]
            : []
        )
      }
    }
  },
  {
    label: 'does not contain',
    value: 'not_contains',
    requiresValue: true,
    renderWhereValueComponent: props => (
      <RelationInputBranch
        isMulti={false}
        {...props}
        allowedValues={
          props.allowMultipleAnswers && props.allowedValues
        }
      />
    ),
    relationMapToWhereArg: (val, key) => ({
      [key]: {
        some: {
          id: {
            notIn: val.filter(v => v.id).map(v => v.id)
          }
        }
      }
    }),
    mapToWhereArg: (val, key) => {
      return {
        OR: [
          {
            [key]: {
              ...(Array.isArray(val)
                ? {
                    in: val.map(v => handleFloatVsText(key, v))
                  }
                : {
                    not: { contains: handleFloatVsText(key, val) },
                    mode: 'insensitive'
                  })
            }
          }
          //handle multi text or multi number
        ].concat(
          key === 'text' || key === 'number'
            ? [
                {
                  NOT: {
                    [`multi${capitalize(key)}`]: Array.isArray(val)
                      ? {
                          hasSome: val?.map(val =>
                            handleFloatVsText(key, val)
                          )
                        }
                      : { has: handleFloatVsText(key, val) }
                  }
                }
              ]
            : []
        )
      }
    }
  },
  {
    label: 'between',
    value: 'between',
    requiresValue: true,
    //only allowed for date times
    renderWhereValueComponent: props => (
      <DateRangePickerInput {...props} />
    ),
    mapToWhereArg: (val, key) => {
      return {
        [key]: {
          gte: val.startDate,
          lte: val.endDate
        }
      }
    }
  }
]

export const getOperator = operator => {
  return operators.find(o => operator === o.value)
}

export default operators
