import { useState } from 'react'
import SideNav from '../SideNav/SideNav'
import TopNav from '../TopNav/TopNav'
import { useWindowSize } from 'src/styles'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { useResetScheduler } from 'src/app/hooks/useGetTodaysAssignment'
import { useDispatcherResetScheduler } from 'src/app/hooks/useGetTodaysDispatcher'

const MainNav = ({ mainContent, minimal }) => {
  const { loggedIn } = useGetCurrentAgencyContext()
  useResetScheduler()
  useDispatcherResetScheduler()

  const size = useWindowSize()
  const [sideNavIsOpen, setSideNavIsOpen] = useState(
    !size.smallScreen
  )

  return (
    <>
      <TopNav
        handleSidebarOpenState={setSideNavIsOpen}
        sidebarOpenState={sideNavIsOpen}
        hideHamburger={!loggedIn || minimal}
      />

      <SideNav
        sidebarOpenState={sideNavIsOpen}
        mainContent={mainContent}
        showSideNav={loggedIn && !minimal}
        handleOpenState={setSideNavIsOpen}
      />
    </>
  )
}

export default MainNav
