/** @jsxImportSource theme-ui */
import { Box, Flex } from 'theme-ui'
import { Space } from '..'
import BulkSubmissionEditTable from './BulkSubmissionEditTable'
import { Divider, Overline } from '../Shared'
import BulkSubmissionResourceModal from './BulkSubmissionResourceForm'
import EditLocationModal from '../Dispatch/EditDispatchSubmissionLocation'
import LocationRenderer from '../Shared/LocationRenderer'
import BulkSubmissionNoteModal from './BulkSubmissionNoteForm'
import { RichTextRender } from '../Shared/RichTextRender'
import { prettyDate } from 'src/utils'

const BulkSubmissionEditContent = ({
  breakdown,
  incidentTime,
  submissionIds,
  cacheQuery,
  cacheKey,
  variables,
  createdAt,
  dispatches,
  note,
  id
}) => {
  const location = breakdown[0]?.location

  return (
    <div>
      <Space height={5} />
      <>
        <Flex sx={{ alignItems: 'center' }}>
          <h4 style={{ margin: 0 }}>Bulk Submission Details</h4>
        </Flex>

        <p style={{ marginLeft: 5, margin: 0 }}>
          {breakdown?.[0]?.reporter
            ? `Submitted by ${
                breakdown[0]?.reporter.name
              } on ${prettyDate(createdAt)}`
            : `Submitted on ${prettyDate(createdAt)}`}
        </p>
      </>

      <Divider margin="20px 0px" />
      <Box sx={{ mb: 20 }}>
        <strong>Occurrence Date: </strong>
        {prettyDate(incidentTime, 'ddd LL')}
      </Box>
      <BulkSubmissionEditTable
        breakdown={breakdown}
        incidentTime={incidentTime}
        cacheQuery={cacheQuery}
        cacheKey={cacheKey}
        variables={variables}
        id={id}
      />
      <Box
        sx={{
          p: 10,
          position: 'relative',
          mb: '10px'
        }}
      >
        <Overline
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '10px',
            mb: '5px'
          }}
        >
          Resources{' '}
          <BulkSubmissionResourceModal
            resources={dispatches}
            destination={location?.position}
            submissionIds={submissionIds}
            cacheQuery={cacheQuery}
            cacheKey={cacheKey}
            variables={variables}
          />
        </Overline>

        {dispatches?.length > 0 ? (
          <BulkSubmissionResourceRenderer dispatches={dispatches} />
        ) : (
          '--'
        )}
      </Box>
      <Box
        sx={{
          p: 10,
          position: 'relative',
          mb: '10px'
        }}
      >
        <Overline
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '10px',
            mb: '5px'
          }}
        >
          Location{' '}
          <EditLocationModal
            submissionIds={submissionIds}
            mutationOptions={{
              refetchQueries: [
                {
                  query: cacheQuery,
                  variables
                }
              ]
            }}
            {...location}
          />
        </Overline>

        {location ? (
          <Box>
            <LocationRenderer location={location} />
          </Box>
        ) : (
          '--'
        )}
      </Box>
      <Box
        sx={{
          p: 10,
          position: 'relative',
          mb: '10px'
        }}
      >
        <Overline
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '10px',
            mb: '5px'
          }}
        >
          Notes / Narrative
          <BulkSubmissionNoteModal
            note={note}
            submissionIds={submissionIds}
            cacheQuery={cacheQuery}
            cacheKey={cacheKey}
            variables={variables}
          />
        </Overline>
        {note ? <RichTextRender text={note} /> : '--'}
      </Box>
    </div>
  )
}
const BulkSubmissionResourceRenderer = ({ dispatches }) => {
  return (
    <Flex>
      <Flex
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '15px',
          flexWrap: 'wrap'
        }}
      >
        {dispatches
          ?.filter(d => !d.archived)
          .map((d, k) => {
            const addComma = dispatches.length - 1 !== k
            return (
              <Flex
                key={k}
                sx={{
                  gap: '5px'
                }}
              >
                {d.unit && (d.unit.name || 'Unassigned')}
                {d.personnel &&
                  ` (${d.personnel.name || 'Unassigned'})`}
                {addComma && ', '}
              </Flex>
            )
          })}
      </Flex>
    </Flex>
  )
}

export default BulkSubmissionEditContent
