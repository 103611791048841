import { MultiValueInput } from '../../../Shared/MultiValueInput'

import ModalButton from '../../../Shared/ModalButton'
import CustomSelectInput from 'src/app/components/Forms/CustomSelectInput'

import uniq from 'lodash/uniq'

import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'

import Button from 'src/app/components/Shared/Elements/Button'
import Label from 'src/app/components/Shared/Elements/Label'
import Input from 'src/app/components/Shared/Elements/Input'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'

import { getFieldConfig } from './dataMapping'
import capitalize from 'lodash/capitalize'
import InlineTooltip from 'src/app/components/Shared/InlineTooltip'
import Icon from 'src/app/components/Shared/Icon'
import filterNodesByType from 'src/utils/filterNodesByType'
import InputGroupText from 'src/app/components/Shared/Elements/InputGroupText'
import { Box } from 'theme-ui'

const DATE_OF_CHANGE_MULITSELECT = '2022-03-01'

const FormFieldItemFormAllowedValuesModal = ({
  fieldItem,
  warnings,
  handleChange,

  formCreatedAt,
  handleWarnings
}) => {
  const agency = useGetCurrentAgencyContext()
  const inelligibleToChangeMultiSelect =
    fieldItem.existing &&
    new Date(formCreatedAt) < new Date(DATE_OF_CHANGE_MULITSELECT)
  const positionCategories =
    agency?.currentAgency?.positionCategories || []
  const hasValidations =
    (fieldItem.allowedValues && fieldItem.allowedValues.length > 0) ||
    fieldItem.allowMultipleAnswers ||
    fieldItem.valueTypeFormat ||
    fieldItem.useAssignment

  return (
    <ModalButton
      renderModalActions={toggle => (
        <Button onClick={toggle}>Done</Button>
      )}
      buttonLabel={
        <Icon
          icon="checkboxchecked"
          height={15}
          width={15}
          title="Add Multiple Choice"
          color={hasValidations ? 'success' : 'text'}
        />
      }
      buttonProps={{
        variant: 'link',
        sx: { padding: '0px' }
      }}
    >
      {() => {
        const fieldKey = 'allowedValuesInput' + fieldItem.id

        const hasAllowedValues =
          fieldItem.allowedValues &&
          fieldItem.allowedValues.length > 0

        const fieldConfig = getFieldConfig(fieldItem)
        const isARelationField = fieldConfig.treatAsRelation
        const agencyCategories = fieldConfig?.isNodeType
          ? positionCategories
          : []

        const nodes = agency[fieldConfig.value] || []
        const relationNodes = fieldItem?.valueTypeFormat
          ? filterNodesByType(nodes, fieldItem.valueTypeFormat)
          : nodes
        const hasOptions =
          hasAllowedValues || fieldConfig.treatAsRelation

        return (
          <>
            {fieldItem?.autoUpdates?.length > 0 && (
              <InlineTooltip
                variant="danger"
                sx={{ my: 20 }}
                tip="This question has a status connected, don't forget to
                update the status in Pulse to match the restricted options
                here."
              />
            )}
            {(!fieldItem.valueTypeFormat ||
              fieldConfig.treatAsRelation) && (
              <>
                <FormGroup>
                  <Label>Restrict Options To</Label>
                  {isARelationField ? (
                    <CustomSelectInput
                      options={[
                        {
                          id: 'groups',
                          label: 'Groups',
                          options: agencyCategories
                        },
                        {
                          id: fieldConfig.value,
                          label: fieldConfig.value,
                          options: relationNodes
                        }
                      ]}
                      value={[
                        ...agencyCategories,
                        ...relationNodes
                      ].filter(
                        opt =>
                          fieldItem.allowedValues &&
                          fieldItem.allowedValues.includes(opt.id)
                      )}
                      isMulti
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      onChange={selected => {
                        handleChange({
                          value: selected
                            ? selected.map(s => s.id || s)
                            : [],
                          name: 'allowedValues'
                        })
                      }}
                    />
                  ) : (
                    <MultiValueInput
                      id={fieldKey}
                      name={fieldKey}
                      placeholder="Enter the options you want to allow as answers. Press enter or click the plus to add multiple."
                      value={fieldItem[fieldKey]}
                      defaultArrayValue={fieldItem.allowedValues}
                      onChange={e => {
                        if (
                          !e.target.value ||
                          e.target.value.length === 0
                        ) {
                          handleWarnings(e, true)
                          handleChange(e)
                          return
                        }

                        handleChange(e)
                      }}
                      errorMessage={warnings[fieldKey]}
                      onAdd={curArray => {
                        if (warnings[fieldKey]) {
                          return
                        }

                        handleChange({
                          value: uniq(curArray),
                          name: 'allowedValues'
                        })
                        handleChange({
                          value: '',
                          name: fieldKey
                        })
                      }}
                      type={
                        fieldItem.type === 'text' ? 'text' : 'number'
                      }
                    />
                  )}
                </FormGroup>

                {fieldConfig.canHaveOther && hasAllowedValues && (
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name={'allowOther'}
                      checked={
                        hasAllowedValues && fieldItem.allowOther
                      }
                      disabled={!hasAllowedValues}
                      label="Allow Other Option"
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </FormGroup>
                )}
              </>
            )}

            {fieldConfig.canBeMulti && hasOptions && (
              <FormGroup check>
                <Input
                  type="checkbox"
                  checked={
                    !isARelationField
                      ? hasAllowedValues &&
                        fieldItem.allowMultipleAnswers
                      : fieldItem.allowMultipleAnswers
                  }
                  disabled={
                    (!isARelationField && !hasAllowedValues) ||
                    inelligibleToChangeMultiSelect
                  }
                  name={'allowMultipleAnswers'}
                  label={`Allow Multi-select`}
                  onChange={e => {
                    handleChange(e)
                  }}
                />
                {inelligibleToChangeMultiSelect &&
                  !fieldItem.allowMultipleAnswers && (
                    <InputGroupText>
                      This form is older, changing this setting could
                      cause issues with existing data
                    </InputGroupText>
                  )}
              </FormGroup>
            )}

            {fieldConfig?.valueTypeFormats?.length > 0 &&
              (!fieldItem.allowedValues?.length ||
                fieldConfig.treatAsRelation) && (
                <>
                  <h6>Validations / Formats</h6>
                  {fieldConfig?.valueTypeFormats?.map(format => (
                    <FormGroup sx={{ mb: '3px' }}>
                      <Input
                        type="checkbox"
                        checked={fieldItem.valueTypeFormat === format}
                        name={'valueTypeFormat'}
                        label={capitalize(`${format}`)}
                        onChange={e => {
                          handleChange({
                            target: {
                              value: e?.target?.checked && format,
                              name: 'valueTypeFormat'
                            }
                          })
                          handleChange({
                            value: [],
                            name: 'allowedValues'
                          })
                        }}
                      />
                    </FormGroup>
                  ))}
                </>
              )}
            {fieldConfig?.canUseAssignment && (
              <Box sx={{ mt: '20px' }}>
                <h6>Default Answer</h6>

                <FormGroup>
                  <Input
                    type="checkbox"
                    label="Use Today's Assignment"
                    name="useAssignment"
                    checked={fieldItem?.useAssignment}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </FormGroup>
              </Box>
            )}
          </>
        )
      }}
    </ModalButton>
  )
}

export default FormFieldItemFormAllowedValuesModal
