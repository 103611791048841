import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'

import CustomSelectInput from './CustomSelectInput'
import FormGroup from '../Shared/Elements/FormGroup'
import Label from '../Shared/Elements/Label'

import useGetTodaysDispatcher from 'src/app/hooks/useGetTodaysDispatcher'
import { Flex } from 'theme-ui'
import ModalFooter from '../Shared/Elements/ModalFooter'
import { useState } from 'react'
import Button from '../Shared/Elements/Button'

const DailyDispatcherForm = ({ onFinish }) => {
  const { user: me, users } = useGetCurrentAgencyContext()

  const [
    dailyDispatcherStrategy,
    setDailyDispatcherStrategy
  ] = useState(me?.dailyDispatcherStrategy)

  const {
    id = null,
    loading,
    updateDailyDispatcher
  } = useGetTodaysDispatcher()

  const [dispatcherId, setDispatcherId] = useState(id)

  const saveDispatcher = async () => {
    await updateDailyDispatcher({
      todaysDispatcher: dispatcherId || null,
      dailyDispatcherStrategy
    })
    onFinish && onFinish()
  }
  return (
    <>
      <FormGroup style={{ flex: 2 }}>
        <Label htmlFor="sort">Dispatcher</Label>
        <CustomSelectInput
          value={
            dispatcherId
              ? {
                  id: dispatcherId,
                  name: dispatcherId
                    ? users.find(i => i.id === dispatcherId).name
                    : null
                }
              : null
          }
          isClearable
          options={users.filter(u => u.rosterStatus !== 'ARCHIVED')}
          getOptionValue={v => v.id}
          getOptionLabel={v => v.name}
          onChange={suggestion => {
            setDispatcherId(suggestion?.id)
          }}
        />
      </FormGroup>
      <Flex sx={{ flexDirection: 'column', gap: '5px', my: '20px' }}>
        <label>
          <input
            type="checkbox"
            checked={dailyDispatcherStrategy === 'RESET'}
            onChange={() =>
              setDailyDispatcherStrategy(
                dailyDispatcherStrategy === 'RESET' ? '' : 'RESET'
              )
            }
          />
          Reset Daily
        </label>
        <label>
          <input
            type="checkbox"
            checked={dailyDispatcherStrategy === 'AUTO'}
            onChange={() =>
              setDailyDispatcherStrategy(
                dailyDispatcherStrategy === 'AUTO' ? '' : 'AUTO'
              )
            }
          />
          Remember My Assignment
        </label>
      </Flex>
      <ModalFooter onClose={onFinish}>
        <Button
          disabled={loading}
          icon={loading && 'spinner'}
          type="submit"
          variant="primary"
          onClick={saveDispatcher}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

export default DailyDispatcherForm
