/** @jsxImportSource theme-ui */

import { Fragment, memo, useContext, useState } from 'react'

import { prettyDate } from 'src/utils'
import SubmissionsTableActions, {
  ArchiveManySubmissionsModal,
  EditVictimsCount
} from './SubmissionsTableActions'
import SubmissionTableSubcomponent from '../Dispatch/SubmissionTableSubcomponent'

import SurflineWeatherIcons from '../Shared/SurflineWeatherIcons'

import { CenteredContent, Space } from '..'
import { Flex, Box, Text } from 'theme-ui'
import get from 'lodash/get'

import SubmissionImportantButton from './SubmissionImportantButton'
import { renderRowExpanderComponent } from '../Shared/Table/renderRowExpanderComponent'

import LabelBadge from '../Shared/LabelBadge'
import { MoreNotesModal } from '../Shared/Notes'
import groupBy from 'lodash/groupBy'

import { Divider, Overline } from '../Shared'

import { GET_AGENCY_ACTIVITY_FEED } from 'src/app/hooks/queries'
import Button from '../Shared/Elements/Button'
import Badge from '../Shared/Elements/Badge'

import ArchiveFormResponseButton from '../Operations/Forms/ArchiveFormResponseButton'
import BulletinLiveUntilBadge from '../Bulletins/BulletinLiveUntilBadge'
import { BulletinModalContentContainer } from '../Bulletins/BulletinRichTextModal'
import { UrgencyIcon } from '../Bulletins/BulletinIcons'
import ModalButton from '../Shared/ModalButton'
import { RequestPDFReport } from '../Reporting/StatReport'
import uniqBy from 'lodash/uniqBy'
import { Table } from '../Shared/Table'
import { RolesOnly } from 'src/app/services/auth'
import { SubmissionModalContext } from '../Shared/SubmissionModalLayout'
import SpinnerWithTimeoutWarning from '../Shared/SpinnerWithTimeoutWarning'
import { RichTextRender } from '../Shared/RichTextRender'

import {
  RequestSupervisorReviewModalButton,
  SupervisorReviewModalButton
} from './SupervisorReviewModalButton'
import InlineTooltip from '../Shared/InlineTooltip'
import { FormResponseSubmitRenderer } from '../Operations/Forms/formSubmitRenderer/FormResponseSubmitRenderer'

import BulkSubmissionEditContent from './BulkSubmissionEditContent'

export function groupDataByType(data, groupByKey) {
  return Object.entries(
    groupBy(data, item => {
      return get(item, groupByKey, item.id)
    })
  ).map(([, value]) => (value.length > 1 ? value : value[0]))
}

const SubmissionList = memo(
  ({
    submissions: data,
    compact,
    groupBy,
    includedDataTypes,
    loading,
    totalUnpaginatedResults,
    loadingMore,
    onLoadMore,
    variables,
    cacheKey = 'myAgenciesActivityFeed.feed',
    requestReviewOnly,
    refetchQueries = ['GetEvents'],
    cacheQuery = GET_AGENCY_ACTIVITY_FEED
  }) => {
    if (data?.length < 1) {
      return (
        <CenteredContent height={'400px'}>
          {loading ? <SpinnerWithTimeoutWarning /> : 'No incidents.'}
        </CenteredContent>
      )
    }

    if (groupBy) {
      data = groupDataByType(data, groupBy)
    }

    const {
      formResponseCount,
      lastFormResponseId
    } = getFormResponseCountAndLast(data)

    return (
      <Box>
        {data?.length > 0 &&
          data.map((d, i) => {
            if (Array.isArray(d) && d.length > 0) {
              return (
                <Box key={i} sx={{ my: 15 }}>
                  <CallForServiceItem
                    requestReviewOnly={requestReviewOnly}
                    subs={d}
                    compact={compact}
                    variables={variables}
                    refetchQueries={refetchQueries}
                    cacheKey={cacheKey}
                    cacheQuery={cacheQuery}
                  />
                </Box>
              )
            }

            return (
              <Box key={i} sx={{ my: 15 }}>
                {d.__typename === 'Submission' ? (
                  <>
                    <SubmissionItem
                      sub={d}
                      compact={compact}
                      requestReviewOnly={requestReviewOnly}
                      variables={variables}
                      refetchQueries={refetchQueries}
                      cacheKey={cacheKey}
                      cacheQuery={cacheQuery}
                    />
                  </>
                ) : d.__typename === 'FormResponse' ? (
                  <>
                    <FormResponseItem
                      formResponse={d}
                      compact={compact}
                      variables={variables}
                      refetchQueries={refetchQueries}
                      cacheKey={cacheKey}
                      cacheQuery={cacheQuery}
                    />
                    {formResponseCount % 10 === 0 &&
                      lastFormResponseId === d.id &&
                      includedDataTypes?.length > 1 && (
                        <InlineTooltip
                          tip={
                            'The number of form response loaded are limited for performance.  However, you may have more responses before the next incident in this list.  Load more to see them.'
                          }
                          isCloseable={false}
                          variant="warning"
                          action={
                            <Button
                              disabled={loadingMore || loading}
                              icon={loadingMore ? 'spinner' : ''}
                              onClick={onLoadMore}
                              variant="primary"
                            >
                              Load more
                            </Button>
                          }
                        />
                      )}
                  </>
                ) : d.__typename === 'BulkSubmission' ? (
                  <BulkSubmissionItem
                    {...d}
                    breakdown={d.breakdown}
                    createdAt={d.createdAt}
                    incidentTime={d.incidentTime}
                    variables={variables}
                    submissionIds={d.submissionIds}
                    archived={d.archived}
                    cacheKey={cacheKey}
                    cacheQuery={cacheQuery}
                    compact={compact}
                    notes={d.bulkNotes}
                  />
                ) : (
                  <BulletinItem
                    bulletin={d}
                    variables={variables}
                    compact={compact}
                  />
                )}
              </Box>
            )
          })}

        {totalUnpaginatedResults > data?.length && (
          <Flex
            p={20}
            pb={40}
            sx={{
              justifyContent: 'center',
              flexDirection: 'row',
              zIndex: 5
            }}
          >
            <Button
              disabled={loadingMore || loading}
              icon={loadingMore ? 'spinner' : ''}
              onClick={onLoadMore}
              variant="primary"
            >
              Load more{' '}
              {`(${
                totalUnpaginatedResults >= 1000
                  ? '1000+'
                  : totalUnpaginatedResults - data.length
              })`}
            </Button>
          </Flex>
        )}
      </Box>
    )
  }
)

const CallForServiceItem = ({
  subs,
  compact,
  requestReviewOnly,
  cacheKey,
  cacheQuery,
  variables,
  refetchQueries,
  ...props
}) => {
  const positionName = get(subs[0], 'location.position.name', '')
  const prettyAddress = get(subs[0], 'location.prettyAddress', '')
  const supReviews = subs?.filter(s => s?.reviewForm)

  return (
    <ListItemCard
      type={
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          INCIDENT GROUP
          {subs.length && ` (${subs.length} Incidents)`}
          <Flex
            sx={{
              flexDirection: ['column', null, 'row'],
              alignItems: 'flex-end',
              gap: ['5px', null, '15px'],
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}
          >
            {supReviews?.length > 0 &&
              supReviews.map((s, k) => {
                return (
                  <Flex
                    sx={{ alignItems: 'center', gap: '5px' }}
                    key={k}
                  >
                    {s.type?.label}:
                    {requestReviewOnly ? (
                      <RequestSupervisorReviewModalButton
                        formId={s.reviewForm?.id}
                        currentStatus={s.reviewStatus}
                        currentStatusColor={s.reviewStatusColor}
                        submissionId={s.id}
                        callId={subs[0]?.callForService?.callNumber}
                      />
                    ) : (
                      <SupervisorReviewModalButton
                        formId={s.reviewForm?.id}
                        currentStatus={s.reviewStatus}
                        currentStatusColor={s.reviewStatusColor}
                        submissionId={s.id}
                        callId={subs[0]?.callForService?.callNumber}
                      />
                    )}
                  </Flex>
                )
              })}
          </Flex>
        </Flex>
      }
      expandedContent={() =>
        subs.map((s, k) => (
          <SubmissionItem
            sub={s}
            cacheKey={cacheKey}
            cacheQuery={cacheQuery}
            refetchQueries={refetchQueries}
            variables={variables}
            key={k}
            compact={compact}
            style={{ boxShadow: 'none' }}
            requestReviewOnly={requestReviewOnly}
          />
        ))
      }
      compact={compact}
      {...props}
    >
      <ListItemGroup sx={{ maxWidth: ['100%', null, '20%'] }}>
        <Flex sx={{ flexDirection: 'column', mb: '3px', gap: '4px' }}>
          {subs?.every(s => s.archived) && (
            <Box sx={{ mb: '5px' }}>
              <Badge variant="danger" size="sm">
                ARCHIVED
              </Badge>
            </Box>
          )}
          {prettyDate(subs[0]?.incidentTime)}{' '}
          <Box sx={{ fontWeight: 'bold', mb: '5px' }}>
            {subs[0].callForService?.callNumber}
          </Box>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '5px'
            }}
          >
            {compact ? (
              <ShowMoreCollapse
                items={uniqBy(subs, sub => {
                  return sub?.type?.label || 'Unknown'
                }).map((sub, k) => (
                  <LabelBadge
                    key={k}
                    color={sub?.type?.color}
                    text={`${
                      !sub.type ? 'Unknown' : sub.type.label
                    } (${
                      subs.find(s => s.type === sub.type)?.count
                    })`}
                  />
                ))}
              />
            ) : (
              uniqBy(subs, sub => {
                return sub?.type?.label || 'Unknown'
              }).map((sub, k) => (
                <LabelBadge
                  key={k}
                  color={sub?.type?.color}
                  text={`${!sub.type ? 'Unknown' : sub.type.label} (${
                    subs.find(s => s.type === sub.type)?.count
                  })`}
                />
              ))
            )}
          </Flex>
        </Flex>
      </ListItemGroup>
      <ListItemGroup title={'Submitted By'}>
        {subs[0].reporter?.name}
      </ListItemGroup>
      <ListItemGroup title={'Involved'}>
        {subs.reduce((acc, s) => s.count + acc, 0)}
      </ListItemGroup>
      <ListItemGroup title={'Location'}>
        {subs[0].location
          ? `${positionName || ''}
                ${positionName && prettyAddress ? ' | ' : ''}
                ${prettyAddress || ''}`
          : ''}
      </ListItemGroup>
      <Flex
        sx={{
          alignItems: 'flex-start',
          justifyContent: ['flex-start', null, null, 'flex-end'],

          minWidth: '210px',
          gap: '12px'
        }}
      >
        <RolesOnly
          roles={['ADMIN', 'DISPATCHER', 'SUPERVISOR', 'READ_ONLY']}
        >
          <ModalButton
            buttonLabel="Report"
            buttonProps={{ variant: 'warning', icon: 'download' }}
            isValidToggle={() => {
              return true
            }}
          >
            {({ toggle }) => {
              return (
                <RequestPDFReport
                  incidentId={subs[0]?.id}
                  onClose={toggle}
                />
              )
            }}
          </ModalButton>
        </RolesOnly>
      </Flex>
    </ListItemCard>
  )
}

const SubmissionItem = ({
  sub,
  requestReviewOnly,
  variables,
  cacheQuery,
  cacheKey,
  compact
}) => {
  const modalContext = useContext(SubmissionModalContext)
  const showSubmissionModal = modalContext?.showSubmissionModal

  const dispatches = sub.dispatches || []

  const positionName = get(sub, 'location.position.name', '')
  const prettyAddress = get(sub, 'location.prettyAddress', '')

  const supReview = sub?.reviewForm
  const isLiveCall = sub?.dispatches?.some(
    d => !d?.completedAt && d?.wasDispatched
  )

  return (
    <>
      <ListItemCard
        expandedContent={({ setIsOpen, isOpen }) => (
          <SubmissionTableSubcomponent
            row={{
              ...sub,
              toggleRowExpanded: () => setIsOpen(!isOpen)
            }}
            submissionId={sub.id}
            onCancel={() => setIsOpen(false)}
          />
        )}
        type={
          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Flex sx={{ alignItems: 'center', gap: '10px' }}>
              INCIDENT{' '}
              {isLiveCall && (
                <Badge size="sm" variant="success">
                  Active Dispatch
                </Badge>
              )}{' '}
            </Flex>
            <Flex sx={{ alignItems: 'center', gap: '10px' }}>
              {(supReview || sub?.supervisorReviews?.length > 0) && (
                <>
                  Review Status:
                  {requestReviewOnly ? (
                    <RequestSupervisorReviewModalButton
                      formId={supReview?.id}
                      currentStatus={sub.reviewStatus}
                      currentStatusColor={sub.reviewStatusColor}
                      submissionId={sub.id}
                      callId={sub.callForService?.callNumber}
                    />
                  ) : (
                    <SupervisorReviewModalButton
                      formId={supReview?.id}
                      currentStatus={sub.reviewStatus}
                      currentStatusColor={sub.reviewStatusColor}
                      submissionId={sub.id}
                      callId={sub.callForService?.callNumber}
                    />
                  )}
                </>
              )}
            </Flex>
          </Flex>
        }
        compact={compact}
      >
        <ListItemGroup sx={{ minWidth: ['100%', null, '20%'] }}>
          <Flex sx={{ alignItems: 'center' }}>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                mb: '3px',
                gap: '4px',
                width: '100%'
              }}
            >
              <Box sx={{ mb: '5px' }}>
                {sub.archived && (
                  <Badge variant="danger" size="sm">
                    ARCHIVED
                  </Badge>
                )}
              </Box>
              <Flex
                sx={{
                  alignItems: 'flex-start',
                  gap: '3px',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Box>
                  {prettyDate(sub.incidentTime)}{' '}
                  <Box sx={{ fontWeight: 'bold', mb: '5px' }}>
                    {sub.callForService.callNumber}
                  </Box>
                </Box>
                <SubmissionImportantButton
                  submissionId={sub.id}
                  important={sub.important}
                  size="sm"
                  sx={{ px: '0p' }}
                />
              </Flex>
              <Flex
                sx={{
                  gap: '5px',
                  alignItems: 'flex-start'
                }}
              >
                <LabelBadge
                  color={sub.type && sub.type.color}
                  text={!sub.type ? 'Unknown' : sub.type.label}
                />
              </Flex>
              <RolesOnly
                roles={['USER', 'ADMIN', 'DISPATCHER', 'SUPERVISOR']}
              >
                <Button
                  sx={{ paddingTop: 8, px: '0px' }}
                  variant="link"
                  onClick={() => {
                    showSubmissionModal({
                      id: sub.id,
                      typeOnly: true
                    })
                  }}
                >
                  Change
                </Button>
              </RolesOnly>
            </Flex>
          </Flex>
        </ListItemGroup>
        <ListItemGroup title={'Submitted By'}>
          {sub?.reporter?.name}
        </ListItemGroup>
        <ListItemGroup
          title={'Involved'}
          // compact={compact}
          sx={{ maxWidth: '70px' }}
        >
          {typeof sub.count === 'number' ? `${sub.count}` : null}
        </ListItemGroup>

        <ListItemGroup title={'Resources'}>
          <ShowMoreCollapse
            count={2}
            items={dispatches
              .filter(d => !d.archived)
              .map((d, k) => {
                return (
                  <Badge
                    key={k}
                    mw={'100%'}
                    style={{
                      margin: '5px 2px',
                      textAlign: 'left'
                    }}
                  >
                    {d?.unit?.name || 'Unassigned'}
                    {d.personnel && (
                      <div
                        sx={{
                          fontSize: '10px',
                          mt: '3px',
                          textAlign: 'left'
                        }}
                      >
                        {d.personnel.name}
                      </div>
                    )}
                  </Badge>
                )
              })}
          />
        </ListItemGroup>
        <ListItemGroup sx={{ maxWidth: '420px' }} title={'Location'}>
          {sub.location
            ? `${positionName || ''}
                ${positionName && prettyAddress ? ' | ' : ''}
                ${prettyAddress || ''}`
            : ''}
        </ListItemGroup>

        <ListItemGroup
          title={`Notes (${sub.notes.length})`}
          compact={compact}
          sx={{ maxWidth: '120px' }}
        >
          {sub.notes.length > 0 && (
            <MoreNotesModal
              notes={sub.notes}
              style={{ minWidth: '100px', paddingLeft: '0px' }}
              buttonProps={{
                variant: 'link',
                sx: { padding: '0px' }
              }}
            />
          )}
        </ListItemGroup>

        <SubmissionsTableActions
          hasRelated={
            sub.callForService &&
            sub.callForService.hasMultipleSubmissions
          }
          isArchived={sub.archived}
          submissionId={sub.id}
          variables={variables}
          cacheKey={cacheKey}
          cacheQuery={cacheQuery}
        />
      </ListItemCard>
    </>
  )
}

const BulkSubmissionItem = ({
  incidentTime,
  createdAt,
  breakdown = [],
  variables,
  submissionIds,
  compact,
  archived,
  cacheKey,
  cacheQuery,
  id,
  notes
}) => {
  const dispatches = breakdown[0].resources || []

  const positionName = get(breakdown[0], 'location.position.name', '')
  const prettyAddress = get(
    breakdown[0],
    'location.prettyAddress',
    ''
  )

  const note = notes?.[1]?.text || notes?.[0]?.text
  return (
    <ListItemCard
      expandedContent={() => (
        <BulkSubmissionEditContent
          breakdown={breakdown}
          incidentTime={incidentTime}
          cacheQuery={cacheQuery}
          cacheKey={cacheKey}
          variables={variables}
          createdAt={createdAt}
          dispatches={dispatches}
          submissionIds={submissionIds}
          note={note}
          id={id}
        />
      )}
      type="BULK SUBMISSION"
      compact={compact}
    >
      <ListItemGroup sx={{ minWidth: ['100%', null, '20%'] }}>
        <Flex sx={{ flexDirection: 'column', mb: '3px', gap: '4px' }}>
          {archived && (
            <Box sx={{ mb: '5px' }}>
              <Badge variant="danger" size="sm">
                ARCHIVED
              </Badge>
            </Box>
          )}
          {prettyDate(incidentTime)}
          <Text sx={{ fontWeight: 'bold' }}>
            {breakdown.reduce(
              (acc, cur) => acc + cur.involvedParties,
              0
            )}{' '}
            Victims / Involved Across {breakdown.length} Categories
          </Text>
        </Flex>
      </ListItemGroup>
      <ListItemGroup title={'Submitted By'}>
        {breakdown[0].reporter?.name}
      </ListItemGroup>

      <ListItemGroup title={'Resources'}>
        <ShowMoreCollapse
          count={2}
          items={dispatches
            ?.filter(d => !d.archived)
            .map((d, k) => {
              return (
                <Badge
                  mw={'100%'}
                  key={k}
                  style={{ margin: '5px 2px', textAlign: 'left' }}
                >
                  {d?.unit?.name || 'Unassigned'}
                  {d.personnel && (
                    <div
                      sx={{
                        fontSize: '10px',
                        mt: '3px',
                        textAlign: 'left'
                      }}
                    >
                      {d.personnel.name}
                    </div>
                  )}
                </Badge>
              )
            })}
        />
      </ListItemGroup>
      <ListItemGroup title={'Location'}>
        {breakdown[0].location &&
          `${positionName || ''}
                ${positionName && prettyAddress ? ' | ' : ''}
                ${prettyAddress || ''}`}
      </ListItemGroup>
      <ListItemGroup title={'Notes'} compact={compact}>
        <RichTextRender
          style={{ width: '100%' }}
          text={note}
          maxLines={2}
        />
      </ListItemGroup>

      <ArchiveManySubmissionsModal
        ids={submissionIds}
        cacheQuery={cacheQuery}
        cacheKey={cacheKey}
        variables={variables}
        isArchived={archived}
      />
    </ListItemCard>
  )
}

const FormResponseItem = ({
  formResponse,
  variables,
  compact,
  refetchQueries,
  cacheKey,
  cacheQuery,
  ...props
}) => {
  return (
    <ListItemCard
      type="OPERATIONAL FORM"
      compact={compact}
      expandedContent={() => (
        <FormResponseSubmitRenderer
          formResponseId={formResponse?.id}
          formId={formResponse?.form?.id}
          includeReporting={false}
          tableView
        />
      )}
      {...props}
    >
      <ListItemGroup
        sx={{
          minWidth: ['100%', null, '20%']
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            mb: '3px',
            alignItems: 'flex-start'
          }}
        >
          <Flex sx={{ mb: '10px', gap: '5px', alignItems: 'center' }}>
            {formResponse.draft && (
              <Badge size="sm" variant="warning">
                DRAFT
              </Badge>
            )}
            {formResponse.archived && (
              <Badge size="sm" variant="danger">
                ARCHIVED
              </Badge>
            )}
          </Flex>
          {prettyDate(formResponse.createdAt)}

          <Text sx={{ my: '3px', fontWeight: 'bold' }}>
            {formResponse.form.name}
          </Text>
        </Flex>
      </ListItemGroup>

      <ListItemGroup title={'Submitted By'}>
        {formResponse.submittedBy && formResponse.submittedBy.name}
      </ListItemGroup>

      <ListItemGroup compact={compact} title="Summary">
        {formResponse.summary?.length ? (
          <Flex
            sx={{
              flexDirection: 'row',
              maxWidth: '100%',
              flexWrap: 'wrap',
              py: '5px'
            }}
          >
            {formResponse.summary
              .filter(s => s)
              ?.map((s, k) => (
                <Box
                  sx={{
                    borderRight: '1px solid',
                    borderRightColor: 'gray200',
                    px: '8px'
                  }}
                  key={k}
                >
                  <RichTextRender
                    sx={{
                      fontSize: '12px',
                      mb: '3px',
                      mr: '3px'
                    }}
                    mw={['300px', null, '130px']}
                    text={s}
                  />
                </Box>
              ))}
          </Flex>
        ) : (
          '--'
        )}
      </ListItemGroup>
      <ListItemGroup title="Form Tags">
        <ShowMoreCollapse
          items={formResponse?.form?.fileCategories?.map((c, k) => (
            <LabelBadge
              key={k}
              color={c.color}
              style={{
                fontSize: 12
              }}
              text={c.name}
            />
          ))}
        />
      </ListItemGroup>
      {formResponse.form.autoWeather && (
        <ListItemGroup
          title={'Weather'}
          compact={compact}
          hideOnCompact
        >
          <SurflineWeatherIcons
            addUnits
            weather={formResponse?.weather}
          />
        </ListItemGroup>
      )}
      <Flex
        sx={{
          alignItems: 'flex-start',
          justifyContent: ['flex-start', null, null, 'flex-end'],

          minWidth: '210px',
          gap: '12px'
        }}
      >
        <RolesOnly
          roles={['ADMIN', 'DISPATCHER', 'SUPERVISOR', 'READ_ONLY']}
        >
          <ModalButton
            buttonLabel="Report"
            buttonProps={{ variant: 'warning', icon: 'download' }}
            isValidToggle={() => {
              return true
            }}
          >
            {({ toggle }) => {
              return (
                <RequestPDFReport
                  reportName={formResponse.form.name}
                  formResponseId={formResponse.id}
                  onClose={toggle}
                />
              )
            }}
          </ModalButton>
        </RolesOnly>
        <ModalButton
          contentSize={'lg'}
          buttonProps={{
            variant: 'linkGray'
          }}
          modalProps={{ checkOnClose: true }}
          buttonLabel="Edit"
        >
          {({ toggle }) => {
            return (
              <FormResponseSubmitRenderer
                formResponseId={formResponse?.id}
                formId={formResponse?.form?.id}
                onFinish={toggle}
              />
            )
          }}
        </ModalButton>
        <ArchiveFormResponseButton
          id={formResponse.id}
          cacheQuery={cacheQuery}
          cacheKey={cacheKey}
          variables={variables}
          options={{
            refetchQueries: refetchQueries
          }}
          isArchived={formResponse.archived}
        />
      </Flex>
    </ListItemCard>
  )
}

const BulletinItem = ({ bulletin, compact }) => {
  return (
    <ListItemCard
      type={'BULLETIN'}
      compact={compact}
      expandedContent={({ isOpen, setIsOpen }) => (
        <BulletinModalContentContainer
          id={bulletin.id}
          onClose={() => setIsOpen(!isOpen)}
          noModal
        />
      )}
    >
      <ListItemGroup sx={{ maxWidth: ['100%', null, '20%'] }}>
        <Flex sx={{ flexDirection: 'column', mb: '3px' }}>
          {prettyDate(bulletin.createdAt)}
        </Flex>
        <Text
          sx={{
            mb: '5px',
            fontWeight: 'bold'
          }}
        >
          <Flex>
            <UrgencyIcon
              sx={{ mr: '10px' }}
              urgency={bulletin.urgency}
            />
            {bulletin.title ? bulletin.title : '...'}
          </Flex>
        </Text>
      </ListItemGroup>
      <ListItemGroup title={'Posted By'}>
        {bulletin.author?.name || 'Watchtower Team'}
      </ListItemGroup>
      <ListItemGroup title="Pinned until">
        <BulletinLiveUntilBadge {...bulletin} empty="--" />
      </ListItemGroup>
    </ListItemCard>
  )
}

const ListItemCard = ({
  children,
  compact,
  type,
  expandedContent,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box>
      <ItemTypeTag type={type} />
      <Box variant="card" padding={compact && 2}>
        <Flex
          sx={{
            flexDirection: ['column-reverse', null, null, 'row'],
            justifyContent: 'space-between',
            gap: '20px'
          }}
        >
          <Expander
            compact={compact}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
          <Flex
            sx={{
              width: '100%',
              gap: '20px',
              alignItems: compact
                ? ['flex-start', null, 'flex-start']
                : 'flex-start',
              flexDirection: ['column', null, 'row'],
              justifyContent: 'flex-start'
            }}
            {...props}
          >
            {children}
          </Flex>
        </Flex>
        {expandedContent && isOpen && (
          <div
            style={{
              width: '100%',
              minHeight: 300,
              position: 'relative',
              borderTop: '1px solid lightgray',
              paddingTop: '10px',
              marginTop: '10px'
            }}
          >
            {expandedContent({
              isOpen,
              setIsOpen
            })}
          </div>
        )}
      </Box>
    </Box>
  )
}

const ListItemGroup = ({
  children,
  title,
  compact,
  hideOnCompact,
  mw,
  ...props
}) => {
  if (hideOnCompact && compact) return <Fragment />
  return (
    <Box {...props} sx={{ flex: 1, maxWidth: mw, ...props.sx }}>
      {!compact && <Overline>{title}</Overline>}
      {children || '--'}
    </Box>
  )
}

const Expander = ({ isOpen, compact, setIsOpen }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        pr: compact ? '10px' : '20px',
        borderTop: ['1px solid', null, 'none'],
        borderRight: ['none', null, '1px solid'],
        borderColor: ['gray200', null, 'gray200'],
        mr: compact ? '10px' : '20px'
      }}
    >
      {renderRowExpanderComponent({
        row: {
          isExpanded: isOpen,
          getToggleRowExpandedProps: () => ({
            onClick: () => setIsOpen(!isOpen)
          })
        }
      })}
    </Flex>
  )
}

const ItemTypeTag = ({ type }) => {
  return (
    <Box
      variant="card"
      sx={{
        fontSize: '10px',
        alignSelf: 'flex-start',
        textAlign: 'left',
        p: '5px',
        bg: 'background',
        borderBottom: 'none'
      }}
    >
      {type}
    </Box>
  )
}

const ShowMoreCollapse = ({ items, count = 2 }) => {
  const [isOpen, setIsOpen] = useState(false)
  const firstItems = items?.slice(0, count)
  const restOfItems = items?.slice(count)
  return (
    <Flex
      sx={{
        minHeight: '30px',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '5px'
      }}
    >
      {firstItems}
      {restOfItems?.length > 0 && !isOpen && (
        <Button
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          variant="link"
          sx={{ padding: '0px', mt: '4px' }}
        >
          Show ({restOfItems?.length})...
        </Button>
      )}
      {isOpen &&
        restOfItems?.map((item, k) => {
          return <Box key={k}>{item}</Box>
        })}
    </Flex>
  )
}

export default SubmissionList

function getFormResponseCountAndLast(data) {
  const formResponses = data?.filter(
    d => d.__typename === 'FormResponse'
  )
  const formResponseCount = formResponses?.length
  const lastFormResponseId =
    formResponses?.[formResponseCount - 1]?.id
  return { formResponseCount, lastFormResponseId }
}
