import { useState } from 'react'

import AuthenticatedUppyFilePicker from '../../Shared/Uppy/AuthenticatedUppyFilePicker'

import {
  useCreateFile,
  useUpdateFile
} from '../../../hooks/mutations'

import useForm from '../../../hooks/useForms'
import { useToast } from '../../../../components/toasts'
import { cleanGraphQLError } from '../../../components/Forms/FormError'

import CustomSelectInput from '../../../components/Forms/CustomSelectInput'
import {
  GET_MY_AGENCY_DOCUMENTS,
  useGetCurrentAgencyContext
} from 'src/app/hooks/queries'
import { Box, Flex } from 'theme-ui'
import { cloneDeep } from '@apollo/client/utilities'
import uniqBy from 'lodash/uniqBy'
import Button from '../../Shared/Elements/Button'
import Label from '../../Shared/Elements/Label'
import ModalFooter from '../../Shared/Elements/ModalFooter'
import Input from '../../Shared/Elements/Input'
import FormGroup from '../../Shared/Elements/FormGroup'

import { validateUrl } from 'src/utils/validations'
import moment from 'moment'

function generateFileName() {
  return `Attached_${moment().format('LL-HH:mm')}`
}

export const FileUploadForm = ({
  onCreate,
  onFinish,
  submissionId,
  pickerOnly = false,
  buttonText,
  file,
  defaultTags
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const { add } = useToast()

  const { values, setValues, handleChange, handleSubmit } = useForm(
    file ? updateFile : createNewFile,
    {
      ...(pickerOnly && {
        name: generateFileName()
      }),
      ...file,
      ...(defaultTags && { fileCategories: defaultTags })
    }
  )
  const [showFilePicker, setShowFilePicker] = useState(false)
  const { createOneFile, loading } = useCreateFile()

  const { updateOneFile, loading: updating } = useUpdateFile({
    refetchQueries: [
      {
        query: GET_MY_AGENCY_DOCUMENTS,
        variables: {
          where: {
            id: {
              equals: file?.id
            }
          }
        }
      }
    ]
  })

  async function createNewFile() {
    if (!values.name) {
      add({
        content: `Please provide a file name.`,
        color: 'danger'
      })
      return
    }

    if (values.url && values.handle) {
      add({
        content: `Cannot upload a file and add a url.  Please choose one.`,
        color: 'danger'
      })
      return
    }

    try {
      if (values.handle || values.url) {
        let connects = {}

        if (values.fileCategories && values.fileCategories[0]) {
          connects = {
            fileCategories: {
              connect: values.fileCategories.map(fc => ({
                id: fc.id
              }))
            }
          }
        }

        const variables = {
          data: {
            name: values.name,
            ...(values.handle && { filestackId: values.handle }),
            ...(values.fileType && { fileType: values.fileType }),
            ...(values.url && { url: values.url }),
            ...(submissionId && { submissionId }),

            ...connects
          }
        }
        const res = onCreate
          ? await onCreate(variables)
          : await createOneFile({
              variables,
              refetchQueries: ['GetAgencyFilesPList']
            })

        if (res.success || res.data.createOneFile) {
          add({
            color: 'success',
            content: 'File added.'
          })
        }
        onFinish && onFinish()
      } else {
        add({
          content: 'Error uploading file, refresh and try again.',
          color: 'danger'
        })
      }

      onFinish && onFinish()
    } catch (e) {
      console.log(e)
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  async function updateFile() {
    if (!values.name) {
      add({
        content: `Please provide a file name.`,
        color: 'danger'
      })
      return
    }
    if (!file) {
      return
    }

    try {
      let connects = {}

      if (values.fileCategories) {
        connects.fileCategories = {
          set: values.fileCategories.map(fc => ({
            id: fc.id
          }))
        }
      }

      if (values.url) {
        if (!validateUrl(values.url)) {
          add({
            content: 'Please provide a valid url',
            color: 'danger'
          })
          return
        } else {
          connects.url = values.url
        }
      }

      if (!values.fileCategories || !values.fileCategories[0]) {
        connects.fileCategories = {
          set: []
        }
      }

      if (values.handle) {
        connects.filestackId = values.handle
      }

      await updateOneFile({
        variables: {
          where: {
            id: file.id
          },
          data: {
            name: values.name,
            ...connects
          }
        }
      })

      onFinish && onFinish()
      add({
        color: 'success',
        content: 'File updated.'
      })
    } catch (e) {
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }
  const categories =
    currentAgency &&
    currentAgency.fileCategories.filter(fc => fc.name !== 'Map Layer')
  return (
    <Box>
      {!pickerOnly && (
        <>
          <FormGroup>
            <Label>Document Name</Label>
            <Input
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              disabled={values.name === 'Agency Logo'}
            />
            {values.name === 'Agency Logo' &&
              "Can't edit the Agency Logo file name"}
          </FormGroup>
          <FormGroup>
            <Label>Tags</Label>
            <CustomSelectInput
              id="fileCategories"
              name="fileCategories"
              isMulti={true}
              getOptionValue={v => v.id}
              getOptionLabel={v => v.name}
              value={values.fileCategories || []}
              isDisabled={defaultTags && defaultTags.length > 0}
              onChange={(selected, action) => {
                setValues(prev => ({
                  ...prev,
                  fileCategories: selected
                }))
              }}
              options={categories}
            />
          </FormGroup>
        </>
      )}
      <Label>Attach File</Label>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginBottom: 20
        }}
      >
        <Button
          variant={values.handle ? 'success' : 'secondary'}
          disabled={!values.name || values.url}
          onClick={() => setShowFilePicker(true)}
          sx={{
            marginRight: '10px'
          }}
          icon={values.handle ? 'UploadSuccess' : 'Upload'}
        >
          {values.handle
            ? 'File Uploaded!'
            : file
            ? 'Change File'
            : 'Upload File'}
        </Button>
        or
        <FormGroup
          style={{ flex: 1, marginLeft: '10px', marginBottom: 0 }}
        >
          <Input
            id="url"
            name="url"
            placeholder="Add a link. Ex. https://youtube.com/video"
            value={values.url}
            onChange={handleChange}
            disabled={values.handle}
          />
        </FormGroup>
      </Flex>
      <AuthenticatedUppyFilePicker
        agencyId={currentAgency.id}
        show={showFilePicker}
        lazyLoadCreds
        handleClose={() => setShowFilePicker(false)}
        onSuccess={res => {
          if (res && res.length > 0) {
            setValues(prev => ({
              ...prev,
              fileType: res[0].mimetype,
              handle: res[0].handle
            }))
          } else {
            add({
              content: 'Error uploading file.',
              color: 'danger'
            })
          }
        }}
        limit={1}
      />
      <ModalFooter onClose={onFinish}>
        <Button
          type="button"
          variant={
            !file && (values.handle || values.url)
              ? 'success'
              : 'secondary'
          }
          disabled={
            (!file && !values.handle && !values.url) ||
            updating ||
            loading
          }
          onClick={() => handleSubmit()}
          icon={(updating || loading) && 'spinner'}
        >
          {file
            ? buttonText || 'Update Document'
            : buttonText || 'Create Document'}
        </Button>
      </ModalFooter>
    </Box>
  )
}

export const FilePinButton = ({ fileId, isPinned, ...rest }) => {
  const { add } = useToast()

  const { updateOneFile, loading } = useUpdateFile()

  async function pinFile() {
    try {
      await updateOneFile({
        variables: {
          where: {
            id: fileId
          },
          data: {
            pinned: !isPinned
          }
        },
        refetchQueries: [],
        //Update cache...
        update: (store, { data: { updateOneFile } }) => {
          // Read the data from our cache for this query.
          const data = cloneDeep(
            store.readQuery({
              query: GET_MY_AGENCY_DOCUMENTS,
              variables: {
                take: 10,
                where: {
                  formFieldItemResponse: null,
                  submission: null,
                  archived: { equals: false },
                  pinned: {
                    equals: true
                  }
                }
              }
            })
          )

          if (data && data.myAgenciesFiles) {
            data.myAgenciesFiles = updateOneFile.pinned
              ? uniqBy([updateOneFile, ...data.myAgenciesFiles], 'id')
              : data.myAgenciesFiles.filter(
                  d => d.id !== updateOneFile.id
                )

            // Write our data back to the cache.
            store.writeQuery({
              query: GET_MY_AGENCY_DOCUMENTS,
              variables: {
                take: 10,
                where: {
                  submission: null,
                  pinned: {
                    equals: true
                  }
                }
              },
              data: { ...data }
            })
          }
        }
      })

      add({
        content: isPinned ? 'File unpinned.' : 'File pinned.',
        color: 'success'
      })
    } catch (e) {
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }
  return (
    <Button
      variant={isPinned ? 'success' : 'secondary'}
      onClick={pinFile}
      {...rest}
      disabled={loading}
      icon={loading ? 'spinner' : 'pin'}
    >
      {isPinned ? 'Unpin from home' : 'Pin To Home'}
    </Button>
  )
}
