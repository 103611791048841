import { useMutation } from '@apollo/client'
import ModalButton from '../../Shared/ModalButton'
import ModalFooter from '../../Shared/Elements/ModalFooter'
import Button from '../../Shared/Elements/Button'
import { useToast } from 'src/components/toasts'
import { cleanGraphQLError } from '../../Forms/FormError'
import { LINK_RESPONSE_TO_STEP } from 'src/app/hooks/mutations'
import cloneDeep from 'lodash/cloneDeep'
import { GET_HOMEBASE_TASKS } from 'src/app/hooks/queries'

const LinkResponseToStepModalButton = ({
  responseId,
  stepId,
  stepTitle,
  stepTimestamp,
  variables,
  buttonProps
}) => {
  const { add } = useToast()
  const [linkResponseToStep, { loading }] = useMutation(
    LINK_RESPONSE_TO_STEP
  )

  async function onSubmit() {
    try {
      const res = await linkResponseToStep({
        variables: {
          stepId,
          responseId
        },
        refetchQueries: [
          {
            query: GET_HOMEBASE_TASKS,
            variables
          }
        ]
      })

      if (res && !res.errors) {
        add({
          color: 'success',
          content: 'Response Linked to Step'
        })
      } else {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
      }
    } catch (e) {
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  return (
    <ModalButton
      allowPropagation={false}
      buttonProps={{
        variant: 'link',
        icon: 'linked',
        size: 'sm',
        ...buttonProps
      }}
      buttonLabel={'Link to Step'}
      modalHeader="Link Response to Step"
    >
      {({ toggle }) => {
        return (
          <>
            Are you sure you want to link this response to the step{' '}
            <strong>{stepTitle}</strong> at{' '}
            <strong>{stepTimestamp}</strong>?
            <ModalFooter
              onClose={e => {
                toggle(e)
              }}
            >
              <Button
                variant="success"
                onClick={async e => {
                  await onSubmit()
                  toggle()
                }}
                disabled={loading}
                icon={loading && 'spinner'}
              >
                Yes, Link
              </Button>
            </ModalFooter>
          </>
        )
      }}
    </ModalButton>
  )
}

export default LinkResponseToStepModalButton
