/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui'
import LabelBadge from '../Shared/LabelBadge'
import { Table } from '../Shared/Table'
import {
  ArchiveManySubmissionsModal,
  EditVictimsCount
} from './SubmissionsTableActions'

const BulkSubmissionEditTable = ({
  breakdown,
  cacheQuery,
  cacheKey,
  variables,
  onFinish,
  id
}) => {
  return (
    <Table
      columns={['Category', 'Involved Parties / Victims', '']}
      rows={breakdown.map((b, i) => ({
        values: [
          <LabelBadge
            key={i}
            color={b.type && b.type.color}
            text={`${!b.type ? 'Unknown' : b.type.label}`}
          />,
          <Flex key={b.label} sx={{ alignItems: 'center' }}>
            {b.involvedParties}
            {b.ids.length === 1 && (
              <EditVictimsCount
                submissionId={b.ids[0]}
                count={b.involvedParties}
                cacheQuery={cacheQuery}
                cacheKey={cacheKey}
                variables={variables}
                id={id}
                onFinish={onFinish}
              />
            )}
          </Flex>,
          <ArchiveManySubmissionsModal
            ids={b.ids}
            cacheQuery={cacheQuery}
            cacheKey={cacheKey}
            variables={variables}
            confirmText={`Are you sure you want archive the ${
              !b.type ? 'Unknown' : b.type.label
            } category?`}
            isArchived={false}
            onFinish={onFinish}
          />
        ]
      }))}
    />
  )
}

export default BulkSubmissionEditTable
