import { gql, useQuery, useMutation } from '@apollo/client'
import { useApolloClientContext } from './useApolloClientProvider'
import { UPDATE_DAILY_RESPONDER_STRATEGY } from './mutations'
import { cleanGraphQLError } from '../components/Forms/FormError'
import { useGetCurrentAgencyContext } from './queries'
import { useToast } from 'src/components/toasts'
import { useEffect, useRef } from 'react'

// Define fragment for reuse
const TODAYS_RESPONDER_FIELDS = gql`
  fragment TodaysResponderFields on TodaysResponder {
    responderId
    positionId
    primary
    isLocation
    responderName
    positionName
    __typename
  }
`

const TODAYS_LOCATION_FIELDS = gql`
  fragment TodaysLocationFields on TodaysLocation {
    locationId
    locationName
    __typename
  }
`

const GET_TODAYS_ASSIGNMENT = gql`
  query GetTodaysAssignment {
    todaysResponders @client {
      ...TodaysResponderFields
    }
    todaysLocation @client {
      ...TodaysLocationFields
    }
  }
  ${TODAYS_RESPONDER_FIELDS}
  ${TODAYS_LOCATION_FIELDS}
`

const useGetTodaysAssignment = () => {
  const client = useApolloClientContext()
  const { add } = useToast()
  const { data, error, refetch, loading } = useQuery(
    GET_TODAYS_ASSIGNMENT
  )

  const [
    updateDailyResponderStrategy,
    { loading: updatingDailyResponderStrategy }
  ] = useMutation(UPDATE_DAILY_RESPONDER_STRATEGY)

  async function updateDailyResponder(updateData) {
    try {
      const normalizedData = {
        todaysResponders:
          updateData.todaysResponders?.map(r => ({
            ...r,
            __typename: 'TodaysResponder'
          })) || [],
        todaysLocation: updateData.location?.locationId
          ? {
              locationId: updateData?.location?.locationId || null,
              locationName:
                updateData?.location?.locationName || null,
              __typename: 'TodaysLocation'
            }
          : null
      }

      await client.writeQuery({
        query: GET_TODAYS_ASSIGNMENT,
        data: normalizedData
      })

      if (normalizedData.todaysResponders.length > 0) {
        add({
          content: 'Your daily assignment has been configured.',
          color: 'success'
        })
      }

      if (updateData?.hasOwnProperty('dailyResponderStrategy')) {
        await updateDailyResponderStrategy({
          variables: {
            dailyResponderStrategy: normalizedData.todaysResponders
              .length
              ? updateData.dailyResponderStrategy || null
              : null,
            autoFillAssignments: normalizedData.todaysResponders.map(
              r => ({
                responderId: r.responderId,
                positionId: r.positionId
              })
            ),
            autoFillLocation: updateData.location?.locationId
          }
        }).catch(e => {
          console.error(
            'Error updating daily dispatcher strategy:',
            e
          )
        })
      }

      return await client.readQuery({
        query: GET_TODAYS_ASSIGNMENT
      })
    } catch (e) {
      console.error('Error updating daily responder:', e)
      throw e
    }
  }

  async function setupDailyResponders(
    dailyResponderStrategy,
    options = {},
    context = {}
  ) {
    if (
      dailyResponderStrategy === 'AUTO' &&
      options.assignments?.length > 0
    ) {
      const { users, positions } = context
      const location = positions?.find(
        p => p.id === options.locationId
      )

      await updateDailyResponder({
        todaysResponders: options.assignments.map(a => {
          const position = positions?.find(p => p.id === a.positionId)
          const user = users?.find(u => u.id === a.responderId)

          return {
            responderId: a.responderId || null,
            positionId: a.positionId || null,
            primary: a.primary || false,
            positionName: position?.name || null,
            responderName: user?.name || null,
            isLocation: !position?.dispatchable,
            __typename: 'TodaysResponder'
          }
        }),
        location: options.locationId
          ? {
              locationId: options.locationId,
              locationName: location?.name || null,
              __typename: 'TodaysLocation'
            }
          : null
      })
    }
  }

  return {
    setupDailyResponders,
    error: error && cleanGraphQLError(error.message),
    loading: loading || updatingDailyResponderStrategy,
    updateDailyResponder,
    refetch,
    data
  }
}

const RESET_STATUS_KEY = 'dailyResetStatus'
const RESET_LOCK_KEY = 'resetLock'

export const useResetScheduler = () => {
  const client = useApolloClientContext()
  const { add } = useToast()
  const { user } = useGetCurrentAgencyContext()
  const resetTimeoutRef = useRef(null)
  const isResettingRef = useRef(false)

  const clearTimeouts = () => {
    if (resetTimeoutRef.current) {
      clearTimeout(resetTimeoutRef.current)
      resetTimeoutRef.current = null
    }
  }

  const acquireResetLock = () => {
    const now = new Date().getTime()
    const existingLock = localStorage.getItem(RESET_LOCK_KEY)

    if (existingLock) {
      const lockTime = parseInt(existingLock)
      if (now - lockTime < 60000) return false
    }

    localStorage.setItem(RESET_LOCK_KEY, now.toString())
    return true
  }

  const releaseResetLock = () => {
    localStorage.removeItem(RESET_LOCK_KEY)
  }

  const scheduleNextReset = () => {
    const now = new Date()
    const nextReset = new Date(now)

    if (now.getHours() >= 0 && now.getMinutes() >= 0) {
      nextReset.setDate(nextReset.getDate() + 1)
    }

    nextReset.setHours(0, 0, 0, 0)

    const msUntilReset = nextReset.getTime() - now.getTime()

    console.log('Scheduling next reset:')
    console.log('Current time:', now.toLocaleString())
    console.log('Next reset time:', nextReset.toLocaleString())
    console.log('Hours until reset:', msUntilReset / (1000 * 60 * 60))

    return {
      nextResetTime: nextReset.getTime(),
      msUntilReset
    }
  }

  const persistResetStatus = nextResetTime => {
    const status = {
      isResetEnabled: true,
      nextResetTime,
      lastUpdated: new Date().getTime()
    }
    console.log('Persisting reset status:', status)
    localStorage.setItem(RESET_STATUS_KEY, JSON.stringify(status))
  }

  const clearResetStatus = () => {
    console.log('Clearing reset status')
    localStorage.removeItem(RESET_STATUS_KEY)
    releaseResetLock()
    clearTimeouts()
  }

  const resetDailyResponders = async () => {
    try {
      await client.writeQuery({
        query: GET_TODAYS_ASSIGNMENT,
        data: {
          todaysResponders: [],
          todaysLocation: null
        }
      })

      add({
        content: 'Your daily assignment has been reset.',
        color: 'success'
      })
    } catch (e) {
      console.error('Error resetting daily responders:', e)
    }
  }

  const setupResetSchedule = async () => {
    if (isResettingRef.current) {
      console.log('Reset already in progress, skipping...')
      return
    }

    console.log('Setting up reset schedule...')
    clearTimeouts()

    const { nextResetTime, msUntilReset } = scheduleNextReset()
    persistResetStatus(nextResetTime)

    resetTimeoutRef.current = setTimeout(async () => {
      if (!acquireResetLock()) {
        console.log('Another instance is handling the reset')
        return
      }

      try {
        isResettingRef.current = true
        await resetDailyResponders()
        await setupResetSchedule()
      } finally {
        isResettingRef.current = false
        releaseResetLock()
      }
    }, msUntilReset)
  }

  useEffect(() => {
    if (!user) return

    const initializeResetSchedule = async () => {
      const resetStatus = localStorage.getItem(RESET_STATUS_KEY)

      if (user?.dailyResponderStrategy === 'RESET') {
        console.log('Reset strategy enabled, scheduling reset...')

        if (resetStatus) {
          const status = JSON.parse(resetStatus)
          const now = new Date().getTime()

          if (now > status.nextResetTime) {
            if (acquireResetLock()) {
              try {
                await resetDailyResponders()
                await setupResetSchedule()
              } finally {
                releaseResetLock()
              }
            }
          } else {
            const msUntilReset = status.nextResetTime - now
            resetTimeoutRef.current = setTimeout(async () => {
              if (acquireResetLock()) {
                try {
                  await resetDailyResponders()
                  await setupResetSchedule()
                } finally {
                  releaseResetLock()
                }
              }
            }, msUntilReset)
          }
        } else {
          await setupResetSchedule()
        }
      } else {
        clearResetStatus()
      }
    }

    initializeResetSchedule()

    return () => {
      clearTimeouts()
      releaseResetLock()
    }
  }, [user?.dailyResponderStrategy])

  return {
    clearResetStatus
  }
}

export default useGetTodaysAssignment
