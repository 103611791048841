import { css, Global } from '@emotion/react'
import { useLocation } from 'react-router'
import { useStytchMemberSession } from '@stytch/react/b2b'

import { useEffect, createContext, useContext, useState } from 'react'
import Button from 'src/app/components/Shared/Elements/Button'

import { useGetCurrentAgencyContext } from '../app/hooks/queries'

const showOn = [
  '/agency/onboarding',
  '/agency/login',
  '/agency/set-up',
  '/agency/auth-error'
]

const SupportButton = ({ children, buttonProps = {} }) => {
  const { openChat } = useSmallChat()

  return (
    <Button
      variant="primary"
      {...buttonProps}
      onClick={async () => {
        openChat()
      }}
    >
      {children}
    </Button>
  )
}

const HideLauncher = css`
  #Smallchat {
    display: none !important;
  }
`

const SmallChatContext = createContext()
export const useSmallChat = () => useContext(SmallChatContext)

export const SmallChatLoader = ({ children }) => {
  const [hideChat, setHideChat] = useState(true)
  const location = useLocation()
  const { currentAgency, user } = useGetCurrentAgencyContext()

  const { session } = useStytchMemberSession()

  const smallChat =
    typeof window.Smallchat === 'function' ? window.Smallchat : null

  function resetChat() {
    const localStorage = window.localStorage

    if (!smallChat) return
    smallChat?.('reset')
    smallChat?.('reboot')

    Object.keys(localStorage)
      .filter(x => x.startsWith('sc-') || x.includes('slackchat'))
      .forEach(x => localStorage.removeItem(x))
  }

  function openChat() {
    setHideChat(false)
    smallChat?.('show')
    smallChat?.('open')
  }

  useEffect(() => {
    if (showOn.some(so => location.pathname.startsWith(so))) {
      setHideChat(false)
    } else {
      setHideChat(true)
    }
  }, [location, smallChat])

  useEffect(() => {
    if (smallChat?.set) {
      const userProps = user &&
        currentAgency && {
          email: user?.email,
          name: user?.name,
          agencyId: currentAgency?.id,
          agencyName: currentAgency?.name,
          userId: user?.id,
          phoneNumber: user?.phoneNumber || 'Not Provided'
        }

      if (userProps) {
        smallChat?.set('email', userProps.email)
        smallChat?.set('name', userProps.name)
        smallChat?.set('agencyId', userProps.agencyId)
        smallChat?.set('agencyName', userProps.agencyName)
        smallChat?.set('userId', userProps.userId)
        smallChat?.set('phone', userProps.phoneNumber)
      } else {
        Smallchat('reset')
      }
    }

    return () => {}
  }, [currentAgency, user, session, smallChat])

  return (
    <SmallChatContext.Provider
      value={{ resetChat, smallChat: smallChat, openChat }}
    >
      {hideChat && <Global styles={HideLauncher} />}
      {children}
    </SmallChatContext.Provider>
  )
}

export default SupportButton
