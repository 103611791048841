/** @jsxImportSource theme-ui */
import React, { useCallback, useContext, useState } from 'react'

import { Flex, Box } from 'theme-ui'
import CustomSelectInput from 'src/app/components/Forms/CustomSelectInput'
import Icon from 'src/app/components/Shared/Icon'
import ModalButton from 'src/app/components/Shared/ModalButton'
import { RichTextWithEpandedView } from 'src/app/components/Shared/RichTextInput'

import { fieldItemConfig, getFieldConfig } from './dataMapping'

import FormFieldItemFormAlertModal from './FormFieldItemFormAlertModal'
import FormFieldItemFormAllowedValuesModal from './FormFieldItemFormAllowedValuesModal'

import FormFieldItemFormHelpTextModal from './FormFieldItemFormHelpTextModal'
import FormFieldItemFormRequiredButton from './FormFieldItemFormRequiredButton'
import FormFieldItemFormSummaryAnswerButton from './FormFieldItemFormSummaryAnswerButton'
import Button from 'src/app/components/Shared/Elements/Button'
import Switch from 'src/app/components/Shared/Elements/Switch'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import Input from 'src/app/components/Shared/Elements/Input'

import styled from '@emotion/styled'

import FormFieldItemAutomationModal from './FormFieldItemAutomationModal'

import Elipses from 'src/app/components/Shared/Elipses'
import capitalize from 'lodash/capitalize'
import { Overline } from 'src/app/components/Shared'

import { FormBuilderContext } from '.'
import { getTruthyPermissionsFromSettings } from 'src/app/components/PayWalls/PayWallScreen'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import startCase from 'lodash/startCase'

const Field = ({
  onDelete,
  handleChange,
  onCreateItem,
  onRestore,
  onDuplicate,
  hideDuplicate,
  hideDelete,
  disableStatusEditing,
  formIsEligibleForStatuses,
  formCreatedAt,
  formType,
  ...field
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const [includeArchived, setIncludeArchived] = useState(false)

  const handleInputChange = useCallback((e, itemId) => {
    function getInputValue(event) {
      return event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    }

    const value = e.target ? getInputValue(e) : e.value
    const name = e.target ? e.target.name : e.name
    handleChange({ value, name, itemId })
  }, [])

  function onAddFieldItem(item) {
    onCreateItem(item)
  }
  const agencySettings = currentAgency?.settings

  return (
    <Flex
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',

        padding: '25px 15px',
        flexWrap: 'wrap',
        position: 'relative',
        gap: '20px'
      }}
    >
      <Box
        sx={{
          height: '100%',
          flex: 1,
          minWidth: ['100%', null, null, null, '320px'],
          maxWidth: ['100%', null, null, null, '320px'],
          paddingRight: '15px'
        }}
      >
        <h6
          style={{
            width: '100%',
            marginBottom: '20px'
          }}
        >
          Section Title
        </h6>
        <RichTextWithEpandedView
          setSavedValue={value =>
            handleInputChange({ value, name: 'title' })
          }
          savedValue={field.title}
          hideToolbar
          id="title"
          name="title"
          placeholder="Question Title"
          style={{ marginBottom: '10px' }}
        />
      </Box>
      <Box
        sx={{
          flex: 1
        }}
      >
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            marginBottom: '20px'
          }}
        >
          <h6
            style={{
              width: '100%',

              display: 'flex',
              alignItems: 'center'
            }}
          >
            Questions <FieldItemActionHelperModal />
          </h6>

          <Flex
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            {field?.fieldItems?.some(
              f => f.archived || !f.checked
            ) && (
              <Switch
                style={{ cursor: 'pointer' }}
                id={'includeArchived' + field.id}
                name={'includeArchived' + field.id}
                checked={includeArchived}
                readOnly
                label={
                  <>
                    {includeArchived
                      ? 'Showing Archived'
                      : 'Hiding Archived'}
                  </>
                }
                onClick={() => {
                  setIncludeArchived(!includeArchived)
                }}
              />
            )}
            {!hideDuplicate && (
              <Button
                variant="naked"
                type="button"
                onClick={onDuplicate}
              >
                <Icon
                  width="15px"
                  height="15px"
                  color={'gray500'}
                  icon={'duplicate'}
                />
              </Button>
            )}
            {!hideDelete && (
              <Button
                variant="naked"
                type="button"
                onClick={field.archived ? onRestore : onDelete}
                size="sm"
                iconColor={field.archived ? 'success' : 'gray500'}
                sx={{
                  cursor: 'pointer',
                  padding: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                icon={field.archived ? 'Restore' : 'trash'}
              />
            )}
          </Flex>
        </Flex>

        <Flex
          sx={{
            flexWrap: 'wrap'
          }}
        >
          {field.fieldItems
            .filter(f => (includeArchived ? true : f.checked))
            .sort((a, b) =>
              a.createdAt < b.createdAt
                ? -1
                : a.createdAt > b.createdAt
                ? 1
                : 0
            )
            .map(fieldItem => {
              return (
                <FieldItem
                  key={fieldItem.id}
                  totalItems={field.fieldItems.length}
                  formIsEligibleForStatuses={
                    formIsEligibleForStatuses
                  }
                  formCreatedAt={formCreatedAt}
                  fieldId={field.id}
                  hideDuplicate={hideDuplicate}
                  handleInputChange={handleInputChange}
                  fieldItem={fieldItem}
                  disableStatusEditing={disableStatusEditing}
                />
              )
            })}
          <Box>
            <AddFieldItemButtonModal
              style={{ height: '50px' }}
              onAddItem={onAddFieldItem}
              title="+ Add"
              formType={formType}
              agencySettings={agencySettings}
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

const FieldItem = React.memo(
  ({
    handleInputChange,
    fieldId,
    formIsEligibleForStatuses,
    disableStatusEditing,
    formCreatedAt,
    totalItems,
    hideDuplicate,
    fieldItem: f
  }) => {
    const fieldItemId = f.id

    return (
      <FormGroup
        sx={{
          minWidth: ['100%', null, null, '20%'],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginRight: '25px',
          paddingRight: '25px',
          borderRight: 'default'
        }}
        key={fieldItemId}
      >
        <Flex
          sx={{
            width: '100%',
            minWidth: '100px',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '20px'
          }}
        >
          <Box>
            <Overline>
              {capitalize(f.valueTypeFormat || f.title)}
            </Overline>
            <Elipses
              sx={{ textAlign: 'left' }}
              maxLines={1}
              mw={'100px'}
            >
              {capitalize(f.helpText)}{' '}
            </Elipses>
          </Box>
          <Flex
            sx={{
              justifyContent: 'flex-end'
            }}
          >
            {!hideDuplicate && (
              <DuplicateItemButton fieldItem={f} fieldId={fieldId} />
            )}
            <Button
              variant="naked"
              type="button"
              onClick={() =>
                handleInputChange(
                  { value: !f.checked, name: 'checked' },
                  fieldItemId
                )
              }
              size="sm"
              iconColor={!f.checked ? 'success' : 'gray500'}
              icon={f.checked ? 'trash' : 'Restore'}
            />
          </Flex>
        </Flex>

        <Box sx={{ minHeight: '40px', mt: '10px' }}>
          {f.checked && (
            <FieldItemActions
              handleInputChange={handleInputChange}
              totalItems={totalItems}
              index={fieldItemId}
              disableStatusEditing={disableStatusEditing}
              formCreatedAt={formCreatedAt}
              formIsEligibleForStatuses={formIsEligibleForStatuses}
              fieldItem={f}
            />
          )}
        </Box>
      </FormGroup>
    )
  }
)

export default React.memo(Field)

const DuplicateItemButton = ({ fieldItem, fieldId }) => {
  const { handleFieldItemDuplicate } = useContext(FormBuilderContext)

  return (
    <Button
      variant="naked"
      type="button"
      size="sm"
      sx={{
        cursor: 'pointer',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={() => {
        handleFieldItemDuplicate(fieldItem, fieldId)
      }}
      iconColor={'gray500'}
      icon={'duplicate'}
    />
  )
}

const AddFieldItemButtonModal = ({
  onAddItem,
  agencySettings,
  title
}) => {
  const [item, setItem] = useState(null)

  return (
    <ModalButton
      buttonLabel={title || '+ Question'}
      modalHeader={'Select a data type for the question'}
      buttonProps={{
        variant: 'primary',
        sx: {
          height: '36px'
        }
      }}
      onClose={() => {
        setItem(null)
      }}
      renderModalActions={toggle => (
        <Button
          variant={'primary'}
          type="button"
          onClick={() => {
            if (!item) {
              alert('Please select a data type.')
              return
            }
            // hack to allow ordering.. use a created at date
            onAddItem({
              ...item,
              createdAt: new Date().toISOString()
            })
            setItem(null)
            toggle()
          }}
        >
          + Add
        </Button>
      )}
    >
      {() => {
        return (
          <>
            <CustomSelectInput
              type="select"
              id="item"
              name="item"
              value={item}
              getOptionLabel={v => v.title}
              getOptionValue={v => v.value}
              isClearable={false}
              options={fieldItemConfig?.map(fi => {
                const hasPermission =
                  !fi.moduleRequired ||
                  getTruthyPermissionsFromSettings(
                    agencySettings
                  )?.includes(fi.moduleRequired)

                return {
                  ...fi,
                  ...(!hasPermission && {
                    isDisabled: !hasPermission,
                    title: `${fi.title} (Requires ${startCase(
                      fi.moduleRequired
                    )})`
                  })
                }
              })}
              onChange={selected => {
                setItem(selected)
              }}
            />
            {item?.valueTypeFormats?.length > 0 && (
              <Box sx={{ mt: '20px' }}>
                <h6>Validations / Formats</h6>
                {item?.valueTypeFormats?.map(format => (
                  <FormGroup sx={{ mb: '3px' }} check>
                    <Input
                      type="checkbox"
                      checked={item?.valueTypeFormat === format}
                      name={'valueTypeFormat'}
                      label={capitalize(`${format}`)}
                      onChange={e => {
                        setItem({
                          ...item,
                          valueTypeFormat:
                            e?.target?.checked && format
                        })
                      }}
                    />
                  </FormGroup>
                ))}
              </Box>
            )}

            {item?.canUseAssignment && (
              <Box sx={{ mt: '20px' }}>
                <h6>Default Answer</h6>
                <Box>
                  <FormGroup>
                    <Input
                      type="checkbox"
                      label="Use Today's Assignment"
                      name="useAssignment"
                      onChange={e => {
                        setItem({
                          ...item,
                          useAssignment: e?.target?.checked
                        })
                      }}
                      checked={item?.useAssignment}
                    />
                  </FormGroup>
                </Box>
              </Box>
            )}
          </>
        )
      }}
    </ModalButton>
  )
}

const FieldItemActionContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  padding: 0px 3px;
`

const FieldItemActions = ({
  handleInputChange,
  totalItems,
  formCreatedAt,
  disableStatusEditing,
  formIsEligibleForStatuses,
  fieldItem
}) => {
  const {
    warnings,
    errors,
    handleErrors,
    handleWarnings,
    allFields
  } = useContext(FormBuilderContext)

  const {
    canBeValidated,
    canBeStatus,
    canBeSummaryAnswer
  } = getFieldConfig(fieldItem)
  const isEligibleForStatuses =
    canBeStatus && formIsEligibleForStatuses

  return (
    <>
      <Flex sx={{ py: '10px', height: '35px' }}>
        <FieldItemActionContainer>
          <FormFieldItemFormRequiredButton
            handleChange={val => handleInputChange(val, fieldItem.id)}
            fieldItem={fieldItem}
          />
        </FieldItemActionContainer>
        {canBeValidated && (
          <FieldItemActionContainer>
            <FormFieldItemFormAllowedValuesModal
              handleChange={val =>
                handleInputChange(val, fieldItem.id)
              }
              fieldItem={fieldItem}
              handleWarnings={handleWarnings}
              warnings={warnings}
              formCreatedAt={formCreatedAt}
            />
          </FieldItemActionContainer>
        )}

        <FieldItemActionContainer>
          <FormFieldItemFormHelpTextModal
            handleChange={val => handleInputChange(val, fieldItem.id)}
            fieldItem={fieldItem}
            handleErrors={handleErrors}
            errors={errors}
          />
        </FieldItemActionContainer>
        {canBeSummaryAnswer && (
          <FieldItemActionContainer>
            <FormFieldItemFormSummaryAnswerButton
              handleChange={val =>
                handleInputChange(val, fieldItem.id)
              }
              fieldItem={fieldItem}
            />
          </FieldItemActionContainer>
        )}
        <FieldItemActionContainer>
          <FormFieldItemFormAlertModal
            handleChange={val => handleInputChange(val, fieldItem.id)}
            fieldItem={fieldItem}
            handleWarnings={handleWarnings}
            warnings={warnings}
          />
        </FieldItemActionContainer>
        {isEligibleForStatuses && (
          <FieldItemActionContainer>
            <FormFieldItemAutomationModal
              handleChange={val =>
                handleInputChange(val, fieldItem.id)
              }
              fieldItem={fieldItem}
              handleWarnings={handleWarnings}
              warnings={warnings}
              allFields={allFields}
              disableStatusEditing={disableStatusEditing}
            />
          </FieldItemActionContainer>
        )}

        {totalItems > 1 && totalItems !== fieldItem.index && (
          <FieldItemActionContainer>
            <Icon
              onClick={() => {
                handleInputChange(
                  {
                    name: 'createdAt',
                    value: new Date().toISOString()
                  },
                  fieldItem.id
                )
              }}
              width="25px"
              height="23px"
              icon="right arrow"
              style={{ cursor: 'pointer' }}
              title="Move to end of list."
            />
          </FieldItemActionContainer>
        )}
      </Flex>
    </>
  )
}

const FieldItemActionHelperModal = () => {
  return (
    <ModalButton
      buttonProps={{
        variant: 'naked',
        icon: 'help',

        size: 'sm'
      }}
      modalHeader={'Question Options'}
    >
      {() => {
        return (
          <>
            <Flex sx={{ alignItems: 'center', flexWrap: 'wrap' }}>
              <Box sx={{ marginRight: 20 }}>
                <Icon icon="Asterix" width={25} height={25} />
              </Box>
              <Box sx={{ flex: 1 }}>
                Makes question required when the form is being
                submitted.
              </Box>
            </Flex>
            <Flex
              sx={{ alignItems: 'center', my: 20, flexWrap: 'wrap' }}
            >
              <Box sx={{ marginRight: 20 }}>
                <Icon icon="CheckboxChecked" width={25} height={25} />
              </Box>
              <Box sx={{ flex: 1 }}>
                Configures rules to validate answers submitted.
                Multiple choice, multi-select, ect.
              </Box>
            </Flex>
            <Flex
              sx={{ alignItems: 'center', my: 20, flexWrap: 'wrap' }}
            >
              <Box sx={{ marginRight: 20 }}>
                <Icon icon="Help" width={25} height={25} />
              </Box>
              <Box sx={{ flex: 1 }}>
                Add helper text / instructions to the question.
              </Box>
            </Flex>
            <Flex
              sx={{ alignItems: 'center', my: 20, flexWrap: 'wrap' }}
            >
              <Box sx={{ marginRight: 20 }}>
                <Icon icon="pin" width={25} height={25} />
              </Box>
              <Box sx={{ flex: 1 }}>
                Use the answer to this question as a summary when
                displaying form responses.
              </Box>
            </Flex>
            <Flex
              sx={{ alignItems: 'center', my: 20, flexWrap: 'wrap' }}
            >
              <Box sx={{ marginRight: 20 }}>
                <Icon icon="Bell" width={25} height={25} />
              </Box>
              <Box sx={{ flex: 1 }}>
                Subscribe emails and phone numbers to alerts when this
                question is answered.
              </Box>
            </Flex>
            <Flex
              sx={{ alignItems: 'center', my: 20, flexWrap: 'wrap' }}
            >
              <Box sx={{ marginRight: 20 }}>
                <Icon icon="Pulse" width={25} height={25} />
              </Box>
              <Box sx={{ flex: 1 }}>
                Connect statuses in Pulse to questions in your forrms.
              </Box>
            </Flex>
          </>
        )
      }}
    </ModalButton>
  )
}
