import { useMarkFileRead } from 'src/app/hooks/mutations'
import Button from './Elements/Button'

export const ReadFileButton = ({ file, onFinish }) => {
  const { markFileRead, loading: updateLoading } = useMarkFileRead()

  const isRead = file.hasRead

  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '10px',
        marginLeft: 10,
        marginRight: 10,
        cursor: isRead && 'not-allowed'
      }}
      variant={isRead ? 'success' : 'secondary'}
      icon={isRead ? 'like' : updateLoading && 'spinner'}
      onClick={async () => {
        if (!isRead) {
          await markFileRead({
            variables: {
              where: {
                id: file.id
              }
            },
            refetchQueries: ['MyAgencyFiles', 'MyAgencyFilesPList']
          })
          onFinish && onFinish()
        }
      }}
    >
      {isRead ? ' Read' : ' I have read'}
    </Button>
  )
}
