import { useContext, useState } from 'react'
import {
  useUpdateSubmission,
  useUpdateManySubmissions
} from 'src/app/hooks/mutations'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import { GoogleMapsContext } from 'src/components/layout'
import { useToast } from 'src/components/toasts'
import { Box } from 'theme-ui'
import { cleanGraphQLError } from '../Forms/FormError'

import { StatefulGoogleInput } from '../Forms/GoogleMapInput'
import { SubmissionLocationEditFields } from '../Forms/SubmissionLocationEditFields'
import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import ModalFooter from '../Shared/Elements/ModalFooter'
import ModalButton from '../Shared/ModalButton'

export const EditDispatchSubmissionLocation = ({
  onFinish,
  submissionId,
  submissionIds,
  defaultValues,
  mutationOptions
}) => {
  const { add } = useToast()
  const { updateOneSubmission, loading } = useUpdateSubmission(
    mutationOptions
  )
  console.log(submissionIds, submissionId)
  const {
    updateManySubmissions,
    loading: loadingMany
  } = useUpdateManySubmissions(mutationOptions)
  const { getAddressFromLatLng } = useContext(GoogleMapsContext)
  const { locations } = useGetCurrentAgencyContext()
  const [values, setValues] = useState({
    ...defaultValues
  })

  function handleChange(data) {
    setValues({ ...values, ...data })
  }

  async function onSave() {
    const positionLocation = values?.position?.id
      ? {
          position: {
            connect: {
              id: values.position.id
            }
          }
        }
      : {}

    let res
    if (submissionIds?.length > 0) {
      res = await updateManySubmissions({
        variables: {
          triggerSubscription: true,
          data: {
            location: {
              upsert: {
                create: {
                  lat: values.lat || null,
                  lng: values.lng || null,
                  ...positionLocation,
                  prettyAddress: values.prettyAddress
                },
                update: {
                  lat: values.lat || null,
                  lng: values.lng || null,
                  ...positionLocation,
                  prettyAddress:
                    !values.lat && !values.lng
                      ? null
                      : values.prettyAddress
                }
              }
            }
          },
          where: {
            ids: submissionIds
          }
        }
      })
    } else {
      res = await updateOneSubmission({
        variables: {
          triggerSubscription: true,
          data: {
            location: {
              upsert: {
                create: {
                  lat: values.lat || null,
                  lng: values.lng || null,
                  ...positionLocation,
                  prettyAddress: values.prettyAddress
                },
                update: {
                  lat: values.lat || null,
                  lng: values.lng || null,
                  ...positionLocation,
                  prettyAddress:
                    !values.lat && !values.lng
                      ? null
                      : values.prettyAddress
                }
              }
            }
          },
          where: {
            id: submissionId
          }
        }
      })
    }

    if (!res.errors) {
      onFinish()
    } else {
      console.log(res.errors)
      add({
        variant: 'danger',
        content: cleanGraphQLError(res.errors)
      })
    }
  }

  return (
    <Box>
      <Box sx={{ mb: 40 }}>
        <Label>Saved Location</Label>
        <SubmissionLocationEditFields
          onSelect={pos => {
            handleChange({
              position: pos
            })
          }}
          values={{
            locationPosition: values?.position
          }}
          options={locations}
          showCheck={values?.position?.lat && values?.position?.lng}
          onCheck={async e => {
            const prettyAddress = await getAddressFromLatLng({
              lat: parseFloat(values?.position?.lat),
              lng: parseFloat(values?.position?.lng)
            })

            handleChange(
              e.target && e.target.checked
                ? {
                    lat: values?.position?.lat,
                    lng: values?.position?.lng,
                    prettyAddress
                  }
                : {
                    lat: defaultValues?.lat,
                    lng: defaultValues?.lng,
                    prettyAddress: defaultValues?.prettyAddress
                  }
            )
          }}
        />
      </Box>
      <Box sx={{ my: 40 }}>
        <StatefulGoogleInput
          key={4}
          showPrettyAddress={true}
          inputProps={{
            name: 'location',
            id: 'location',
            label: 'Address / Coordinates',
            sx: {
              mb: 10
            }
          }}
          value={values || ''}
          handleSelect={handleChange}
          handleChange={handleChange}
        />
      </Box>

      <ModalFooter onClose={onFinish}>
        <Button
          onClick={onSave}
          disabled={loading || loadingMany}
          icon={(loading || loadingMany) && 'spinner'}
        >
          Update
        </Button>
      </ModalFooter>
    </Box>
  )
}

const EditLocationModal = ({
  submissionId,
  submissionIds,
  mutationOptions,
  ...vals
}) => {
  return (
    <ModalButton
      buttonProps={{
        icon: 'pencil',
        variant: 'link',
        size: 'sm',
        sx: {
          p: '0px'
        }
      }}
    >
      {({ toggle }) => {
        return (
          <EditDispatchSubmissionLocation
            submissionId={submissionId}
            submissionIds={submissionIds}
            onFinish={toggle}
            defaultValues={vals}
            mutationOptions={mutationOptions}
          />
        )
      }}
    </ModalButton>
  )
}

export default EditLocationModal
